export default {
  'Save changes': 'Guardar cambios',
  'Add Google Tag': 'Mide en Google Analytics estadísticas del botón',
  'Web button': 'Botón para web',
  'No channels available': 'No hay canales disponibles',
  'Integrated channel': 'Canal integrado',
  'Other number': 'Otro número',
  'Other account': 'Otra cuenta',
  'Web buttons': 'Botones para web',
  'Web buttons subtitle create': 'Crear un botón de contacto para tu sitio web. Puede ser multicanal o sólo para WhatsApp.',
  'Web buttons subtitle edit': 'Editá tu botón de contacto para tu sitio web.',
  'Add button': 'Agregar botón',
  OtherNumberTooltip: 'Puede utilizar un número de Whatsapp que no haya conectado a Uniboxi. Solo deberá ingresar el número en el formato correcto como el ejemplo a continuación: +5291234567',
  OtherAccountMessengerTooltip: 'Puede utilizar en su botón una cuenta que no haya conectado a Uniboxi. Solo deberá copiar su url en el formato correcto como se muestra debajo: m.me/nombrepágina',
  OtherAccountInstagramTooltip: 'Puede utilizar en su botón una cuenta que no haya conectado a Uniboxi. Solo deberá copiar su url en el formato correcto como se muestra debajo: instagram.com/sucuenta',
  'Choose button type': 'Elija el tipo de botón',

  'Please insert your GTM Id': 'Añade el Measurement Id de tu cuenta de Google Analytics (GA4).',
  'Please insert your GTM Id 2': 'Debes ir a Google Analytics > Admin > Flujos de Datos (Data Streams).',
  'GTM help': 'Una vez que ingreses el Measurement Id de GA4, cada vez que alguien haga clic en alguno de los botones (WhatsApp, Messenger o Instagram) se creará un evento y podrás medir las conversiones que se generan en tu sitio web.',
  youraccount: 'sucuenta',
  pagename: 'nombrepágina',

  'Website code': 'Código para el sitio web',


  // widgets table
  Type: 'Tipo',
  Clicks: 'Clics',
  WhatsappButton: 'Botón Whatsapp',
  MultichannelButton: 'Botón Multicanal',
  'Code for web': 'Código para web',
  NoWidgetsFound: 'Aún no ha agregado botones para su web.',

  'Multichannel button': 'Botón multicanal',
  'Whatsapp button': 'Botón whatsapp',
  'multichannel-type': 'Botón multicanal',
  'whatsapp-type': 'Botón whatsapp',
  ClicksInformation: 'Últimos 30 días',

  // export widget
  'Export your button': 'Exporta tu botón',
  'Enter your email to finish the process': 'Por favor introduzca su correo electrónico para recibir el código de su sitio web',
  'You must enter a valid email': 'Debes ingresar un correo electrónico válido',
  'Email is required': 'El email es requerido',
  'Get code': 'Obtener código',
  'Your email': 'Tu email',

  // delete widget
  confirm_delete_title: '¿Está seguro de que desea eliminar el botón?',
  confirm_delete_subtitle: 'Ya no aparecera en ninguna página web en la que lo hayas insertado.',
  delete_widget: 'Eliminar',
  // build widget title
  build_widget_title: 'Botón de WhatsApp gratis para tu web',
  build_widget_subtitle: 'Crea en segundos un botón de contacto por WhatsApp para tu sitio web y comienza a recibir mensajes. Totalmente gratis.',
  'Start now': 'Empezar ahora',

  // color picker
  'Header color': 'Color del encabezado',
  'Use on button': 'Usar en el botón',
  Background: 'Fondo',
  Restore: 'Restaurar',
  Text: 'Texto',
  Light: 'Claro',
  Dark: 'Oscuro',
  '(default)': '(predeterminado)',

  // header input
  'Header text': 'Texto del encabezado',

  // numbers section
  'Number is required': 'El número es requerido',
  Number: 'Número',
  'Choose the text and color': 'Elige el texto y color',
  Personalization_subtitle: 'Elige los textos y colores con los que te comunicarás con tus clientes',
  'Phone number': 'Número de teléfono',
  'Page name': 'Nombre de página',
  'Account name': 'Nombre de cuenta',
  'Area name': 'Nombre de área',

  // phone input
  Phone_input_subtitle: 'Agregue uno o más números de teléfono, dedicados a diferentes áreas de su negocio. Ejemplo: +5291234567',
  'Add number': 'Añade un número',
  'Add account': 'Añade una cuenta',

  // popup config
  'Advanced options': 'Opciones avanzadas',
  popup_config_1: 'Este widget se abrirá automáticamente después de que el visitante haya visitado 1 página del sitio web y haya pasado al menos 20 segundos en ella. Si desea cambiar esta configuración, modifique las variables a continuación. Sabemos que puede recibir hasta un ',
  popup_config_2: '50% más de mensajes ',
  popup_config_3: 'cuando esta configuración está activada',
  Activated: 'Activado',
  'Number of pages': 'Número de páginas',
  'Time amount (seconds)': 'Tiempo en la página web (segundos)',

  // position selector
  Position: 'Posición',
  position_selector_title: 'Puede elegir si desea que la ventana aparezca en el lado izquierdo o derecho del sitio web',
  Right: 'Derecha',
  Left: 'Izquierda',

  'tooltip tab widget whatsapp': 'Para crear un botón Multicanal, vuelve al menú de botones y agrega uno nuevo.',
  'tooltip tab widget multichannel': 'Para crear un botón WhatsApp, vuelve al menú de botones y agrega uno nuevo.',


  // preview title
  Preview: 'Vista previa',
  preview_title_subtitle: 'Interactúa con el botón para ver cómo se verá en tu sitio web',

  // testimonials
  testimonialBody: 'Contratamos a Uniboxi porque necesitábamos usar Whatsapp para nuestro equipo de atención al cliente de 5 personas. Podemos dividir todos los mensajes entrantes entre el equipo de soporte y clasificar cada conversación en diferentes categorías. Ahora también estamos agregando otro canal (Messenger) para que nuestros clientes también se comuniquen con nosotros directamente desde Facebook.',
  Testimonials: 'Testimonios',

  // know more
  knowMoreTitle: 'Tenga mejores conversaciones con sus clientes usando la mensajería.',
  knowMoreFirstPoint: 'Un tablero simple para ver todos tus mensajes de WhatsApp, Instagram y Facebook Messenger en un solo lugar.',
  knowMoreSecondPoint: 'Agregue a todo su equipo de ventas o soporte y divida los mensajes entrantes entre ellos. Luego monitoree fácilmente las conversaciones de su equipo.',
  knowMorethirdPoint: 'Cierra más tratos enviando la cotización y realiza un seguimiento automático en días. Comienza integrando un número de WhatsApp, cuenta de Instagram o Messenger.',
  'Know more': 'Más Información',


  // get code widget
  GetCodeWidgetTitle: 'El botón de Whatsapp está listo para usar.',
  GetCodeWidgetSubtitle: 'Copia el siguiente código y pégalo en todas las páginas de tu sitio web antes de cerrar la etiqueta de </body>. Si no sabes cómo hacerlo, envíale el código a tu webmaster.',
  YourCodeTitle: 'Tu código',
  YourCodeSubtitle: 'Copia el siguiente código y pégalo en todas las páginas de tu sitio web antes de cerrar la etiqueta de </body>. Si no sabes cómo hacerlo, envíale el código a tu webmaster.',
  'Copy to clipboard': 'Copiar al portapapeles',

  // multichannel input
  'Multichannel subtitle': 'Elija los canales que va a utilizar en su botón.',
  'Multichannel title': 'Agrega los canales que deseas mostrar',

  // errors
  'Number cannot have parenthesis': 'El número no puede tener paréntesis',
  'Number cannot have spaces': 'El número no puede tener espacios',
  'Number cannot have dashes': 'El número no puede tener guiones',
  'Number cannot have plus': 'El número solo puede tener el signo más al principio',
  'Only numbers are allowed': 'Solo se permiten números',
  'A valid number is required': 'Se requiere un número válido',
  'A valid name is required': 'Se requiere un nombre válido',
  'Page name cannot have spaces': 'El nombre de cuenta no puede contener espacios',

  'ID is not valid': 'El id no es válido',
  'Id is too long': 'El id es muy largo',
  'ID must start with G-': 'El id debe empezar con G-',
};
