export default {
  'Basic reports': 'Basic reports',
  Summary: 'Summary',
  Date: 'Date',
  Days: 'Days',
  Weeks: 'Weeks',
  Months: 'Months',
  User: 'User',
  'New conversations': 'New conversations',
  'Incoming messages': 'Incoming messages',
  'Outgoing messages': 'Outgoing messages',
  'Incoming and outgoing messages': 'Incoming and outgoing messages',
  'Pre-approved whatsapp templates': 'Pre-approved whatsapp templates',
  'Manually added': 'Manually added',
  'Reports by category': 'Reports by category',
  'Number of incoming conversations per category': 'Number of incoming conversations per category.',
  'Daily reports': 'Daily reports',
  'No information': 'No information',
  'Loading Page': 'Loading Page',
  'Unread conversations': 'Unread conversations',
  'The number of conversations with unread messages per user is displayed in real time':
    'The number of conversations with unread messages per user is displayed in real time.',
  'User reports': 'Users reports',
  'Conversations by category': 'Conversations by category',
  'Number of incoming cards per category': 'Number of incoming cards per category.',
  'Response time': 'Response time',
  'Check the average response time of your users': 'Check the average response time of your users',
  'User name': 'User name',
  'First answer': 'First answer',
  'Download table': 'Download table',
  AllFem: 'All',
  Categories: 'Categories',
  'Order by': 'Order by',
  'Less time': 'Less time',
  'Upload date': 'Upload date',
  'Longer time': 'Longer time',
  Alphabetically: 'Alphabetically',
  'Base name': 'Base name',
  'Sent messages': 'Sent messages',
  Answers: 'Answers',
  'Response percentages': 'Response percentages',
  'Batch sends on whatsapp': 'Batch sends on whatsapp',
  'Batch for sends on batch subtitle': 'Statistics of mass messages sent by WhatsApp during the selected period.',
  'Average time': 'Response time (minutes)',
  Conversations: 'Conversations',
  'Number of different conversations each user had during the selected period.': 'Number of unique customer conversations per user.',
  'Active conversations per user': 'Active conversations per user',
  'Response time subtitle': 'Average time to reply to new conversations.',
  'New conversations per channel': 'New conversations per channel',
  'Detail with the number of new conversations assigned to each user.': 'Detail with the number of new conversations assigned to each user.',
  'Input and output messages per user': 'Input and output messages per user',
  'The number of messages sent and received by all channels is considered': 'The number of messages sent and received by all channels is considered.',
  'Empty batch send title': 'Marketing sends by WhatsApp',
  'Empty batch send text 1': 'Your customers are on WhatsApp, send them marketing messages and increase your sales. You can send hundreds or thousands of messages at once. Write us at',
  'Empty batch send text 2': 'to coordinate the sending.',
  'Need a minimum of 10 conversations': 'A minimum of 10 conversations is needed to display the statistic.',
  'All (max. 6 months)': 'All (max. 6 months)',
  'Without information': 'No information',
};
