export default {
  Integrations: 'Integraciones',
  Reports: 'Reportes',
  Tools: 'Herramientas',
  Stadistics: 'Estadisticas',
  Templates: 'Plantillas',
  Sequences: 'Secuencias',
  Users: 'Usuarios',
  'Assignment rules': 'Reglas de asignación',
  'User account': 'Cuenta',
  'Account and preferences': 'Cuenta y preferencias',
  Company: 'Compañía',
  'Prices and plans': 'Precios y planes',
  'Payment history': 'Historia de pago',
  Account: 'Cuenta',
  'Sign out': 'Cerrar sesión',
  'See all': 'Ver todas',
  'Back to home': 'Volver al inicio',
  'Go to dashboard': ' Ir al tablero',
  'Add and delete users': 'Agregar y eliminar usuarios',
  'Add and delete areas': 'Agregar y eliminar areas',
  'Use pre-defined messages': 'Usa mensajes pre definidos',
  'Automate tracking messages': 'Automatiza mensajes de seguimiento',
  'Distribute the conversations in Uniboxi': 'Reparte las conversaciones en Uniboxi',
  'WhatsApp messages': 'Mensajes de WhatsApp',
  Support: 'Soporte',
  'Contact our support center': 'Enviar correo a soporte',
  'Web notifications': 'Notificationes en Chrome',
  'Notify me when:': 'Notificarme cuando:',
  'Receive a new message': 'Reciba un nuevo mensaje',
  'Only when a new conversation enters Uniboxi':
    'Sólo cuando ingresa una nueva conversación a Uniboxi',
  Never: 'Nunca',
  'Notifications are paused until: time': 'Notificaciones pausadas hasta las {{time}}',
  'Notifications are paused until tomorrow': 'Notificaciones pausadas hasta mañana',
  'Pause web notifications': 'Pausar notificaciones en Chrome',
  '15 minutes': '15 minutos',
  '30 minutes': '30 minutos',
  '1 hour': '1 hora',
  '2 hours': '2 horas',
  'Until tomorrow': 'Hasta mañana',
  'Resume notifications': 'Resumir notificaciones',
  'Notifications are disabled in the browser. Follow the instructions below if you want to activate them':
    'Las notificationes están deshabilitadas en el navegador. Sigue las instrucciones de abajo si deseas activarlas',
  'Request permission': 'Pedir permiso',
  'Contact us via email': 'Contáctanos vía correo electrónico',
  'Contact us via WhatsApp': 'Contáctanos vía WhatsApp',
  'Contact us via Instagram': 'Contáctanos vía Instagram',
  'Contact us via Messenger': 'Contáctanos vía Messenger',
  'Support center': 'Centro de soporte',
  'Contact us by the means you prefer': 'Contáctanos por el medio que prefieras',
  'Complete guide to use Uniboxi': 'Guía completa para ocupar Uniboxi',
  'User guide': 'Guía de usuario',
  'Email notifications': 'Notificaciones por correo',
  'Notify me if I receive a new message': 'Notificarme cuando tengo mensajes sin leer',
  'Every 15 minutes': 'Cada 15 minutos',
  'Every 5 minutes': 'Cada 5 minutos',
  'Every 1 hour': 'Cada 1 hora',
};
