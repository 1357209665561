export default {
  General: 'General',
  'Select all': 'Select all',
  Starred: 'Starred',
  Unread: 'Unread',
  Tags: 'Tags',
  Propietary: 'Propietary',
  'Search propietary': 'Search propietary',
  filter: 'filter',
  applied: 'applied',
  'Create date': 'Create date',
  Today: 'Today',
  Yesterday: 'Yesterday',
  'This week (Mon-Sun)': 'This week (Mon-Sun)',
  'This week': 'This week',
  'Past week': 'Past week (Mon-Sun)',
  'Last 14 days': 'Last 14 days',
  'Last 7 days': 'Last 7 days',
  'Last week (Mon - Sun)': 'Last week (Mon - Sun)',
  'Past 2 weeks': 'Past 2 weeks',
  'This month': 'This month',
  'Last 30 days': 'Last 30 days',
  'Past month': 'Past month',
  'Without tags': 'Without tags',
  'applied filters': 'Applied filters',
  'applied filter': 'Applied filter',
};
