// @flow

import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import { includes } from 'lodash';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core';
import ErrorBoundary from '../ErrorBoundary';
import lazyRetry from '../utils/lazyRetry';

import SuspenseFallback from './common/SuspenseFallback';

const Login = lazy(() => lazyRetry(() => import(/* webpackChunkName: 'Login' */ './Login/Login')));
const ForgotPassword = lazy(() => lazyRetry(() => import(/* webpackChunkName: 'ForgotPassword' */ './Login/ForgotPassword')));
const ResetPassword = lazy(() => lazyRetry(() => import(/* webpackChunkName: 'ResetPassword' */ './Login/ResetPassword')));
const RefreshPassword = lazy(() => lazyRetry(() => import(/* webpackChunkName: 'RefreshPassword' */ './Login/RefreshPassword')));
const Register = lazy(() => lazyRetry(() => import(/* webpackChunkName: 'Register' */ './Login/Register')));
const RegisterActivation = lazy(() => lazyRetry(() => import(/* webpackChunkName: 'RegisterActivation' */ './Login/registerActivation/RegisterActivation')));
const RegisterPassword = lazy(() => lazyRetry(() => import(/* webpackChunkName: 'RegisterPassword' */ './Login/RegisterPassword')));
const AuthenticatedRouter = lazy(() => lazyRetry(() => import(/* webpackChunkName: 'AuthenticatedRouter' */ './AuthenticatedRouter')));
// import AuthenticatedRouter from './AuthenticatedRouter';

const theme = createTheme({
  palette: {
    primary: {
      light: '#235a9e',
      main: '#3281e3',
      dark: '#5b9ae8',
      contrastText: '#fff',
    },
    /*
    secondary: {
      light: '#ffff7a',
      main: '#face48',
      dark: '#c39d07',
      contrastText: '#000',
    }, */
  },
  typography: {
    // Use the system font.
    fontFamily: 'Quicksand',
  },
  overrides: {
    MuiChecked: {
      '&$checked': {
        color: '#3281e3',
      },
    },
  },
});

class PublicRouter extends Component {
  componentDidMount() {
    const { history } = this.props;

    const auth = localStorage.getItem('uniboxi-auth');

    if (
      auth
      && includes(
        ['/login', '/forgot', '/refresh', '/register', '/registerPassword'],
        window.location.pathname,
      )
    ) {
      history.replace('/');
    }

    if (
      !auth
      && !includes(
        ['/login', '/forgot', '/refresh', '/register', '/registerPassword'],
        window.location.pathname,
      )
      && !(window.location.pathname.search('/registerActivation/') === 0)
      && !(window.location.pathname.search('/register/') === 0)
      && !(window.location.pathname.search('/registerPassword/') === 0)
      && !(window.location.pathname.search('/reset/') === 0)
    ) {
      history.replace('/login');
    }
  }

  render() {
    return (
      <div>
        <ErrorBoundary>
          <ThemeProvider theme={theme}>
            <Suspense fallback={<SuspenseFallback />}>
              <Switch>
                <Route exact path="/forgot" component={ForgotPassword} />
                <Route exact path="/reset/token/:token" component={ResetPassword} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/refresh" component={RefreshPassword} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/register/:key" component={Register} />
                <Route exact path="/register/:key/:lang" component={Register} />
                <Route exact path="/registerActivation" component={RegisterActivation} />
                <Route
                  exact
                  path="/registerActivation/token/:token/:lang"
                  component={RegisterActivation}
                />
                <Route exact path="/registerPassword/token/:token" component={RegisterPassword} />
                <Route path="/" component={AuthenticatedRouter} />
              </Switch>
            </Suspense>
          </ThemeProvider>
        </ErrorBoundary>
      </div>
    );
  }
}

export default PublicRouter;
