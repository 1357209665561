export default {
  'Pacific/Wallis': 'International Date Line West',
  'Pacific/Midway': 'Midway Island, Samoa',
  'US/Hawaii': 'Hawaii',
  'US/Alaska': 'Alaska',
  'US/Pacific': 'Pacific Time (US and Canada); Tijuana',
  'US/Mountain': 'Mountain Time (US and Canada)',
  'America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
  'US/Arizona': 'Arizona',
  'US/Central': 'Central Time (US and Canada)',
  'Canada/Saskatchewan': 'Saskatchewan',
  'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
  'America/Managua': 'Central America',
  'US/Eastern': 'Eastern Time (US and Canada)',
  'US/East-Indiana': 'Indiana (East)',
  'America/Lima': 'Bogota, Lima, Quito',
  'Canada/Atlantic': 'Atlantic Time (Canada)',
  'America/Caracas': 'Caracas, La Paz',
  'America/Santiago': 'Santiago',
  'Canada/Newfoundland': 'Newfoundland and Labrador',
  'America/Sao_Paulo': 'Brasilia',
  'America/Buenos_Aires': 'Buenos Aires, Georgetown',
  'America/Godthab': 'Greenland',
  'Atlantic/Azores': 'Azores',
  'Atlantic/Cape_Verde': 'Cape Verde Islands',
  'Europe/London': 'Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London',
  'Africa/Casablanca': 'Casablanca, Monrovia',
  'Europe/Budapest': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Warsaw': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Paris': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Amsterdam': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Africa/Lagos': 'West Central Africa',
  'Europe/Athens': 'Athens, Bucharest',
  'Africa/Cairo': 'Cairo',
  'Europe/Helsinki': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Istanbul': ' Istanbul',
  'Europe/Minsk': 'Minsk',
  'Asia/Jerusalem': 'Jerusalem',
  'Africa/Harare': 'Harare, Pretoria',
  'Europe/Moscow': 'Moscow, St. Petersburg, Volgograd',
  'Asia/Kuwait': 'Kuwait, Riyadh',
  'Africa/Nairobi': 'Nairobi',
  'Asia/Baghdad': 'Baghdad',
  'Asia/Tehran': 'Tehran',
  'Asia/Muscat': 'Abu Dhabi, Muscat',
  'Asia/Baku': 'Baku',
  'Asia/Tbilisi': ' Tbilisi',
  'Asia/Yerevan': 'Yerevan',
  'Asia/Kabul': 'Kabul',
  'Asia/Yekaterinburg': 'Ekaterinburg',
  'Asia/Karachi': 'Islamabad, Karachi',
  'Asia/Tashkent': 'Tashkent',
  'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi',
  'Asia/Katmandu': 'Kathmandu',
  'Asia/Dhaka': 'Astana, Dhaka',
  'Asia/Calcutta': 'Sri Jayawardenepura',
  'Asia/Novosibirsk': 'Almaty, Novosibirsk',
  'Asia/Rangoon': 'Yangon Rangoon',
  'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
  'Asia/Krasnoyarsk': 'Krasnoyarsk',
  'Asia/Hong_Kong': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
  'Asia/Taipei': 'Taipei',
  'Australia/Perth': 'Perth',
  'Asia/Ulaanbaatar': 'Ulaanbaatar',
  'Asia/Irkutsk': 'Irkutsk',
  'Asia/Seoul': 'Seoul',
  'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
  'Asia/Yakutsk': 'Yakutsk',
  'Australia/Darwin': 'Darwin',
  'Australia/Adelaide': 'Adelaide',
  'Australia/Melbourne': 'Canberra, Melbourne, Sydney',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Hobart': 'Hobart',
  'Asia/Vladivostok': 'Vladivostok',
  'Pacific/Port_Moresby': 'Guam, Port Moresby',
  'Pacific/Guadalcanal': 'Solomon Islands, New Caledonia',
  'Asia/Magadan': 'Magadan',
  'Pacific/Fiji': 'Fiji Islands',
  'Asia/Kamchatka': 'Kamchatka',
  'Pacific/Auckland': 'Auckland, Wellington',
  'Pacific/Tongatapu': "Nuku'alofa",
};
