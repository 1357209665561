import './wdyr'; // <--- first import

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import client from './apolloClient';
import { RecorderProvider } from './contexts/RecorderProvider';
import { VariablesProvider } from './contexts/VariablesProvider';

import Router from './Router';

import './styles/index.css';
import * as serviceWorker from './serviceWorker';
import './fontawesome';


ReactDOM.render(
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <VariablesProvider>
          <RecorderProvider>
            <Router />
          </RecorderProvider>
        </VariablesProvider>
      </ApolloProvider>
    </BrowserRouter>
  </MuiPickersUtilsProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

document.getHTML = function (who, deep) {
  if (!who || !who.tagName) return '';
  let txt;
  let ax;
  let el = document.createElement('div');
  el.appendChild(who.cloneNode(false));
  txt = el.innerHTML;
  if (deep) {
    ax = txt.indexOf('>') + 1;
    txt = txt.substring(0, ax) + who.innerHTML + txt.substring(ax);
  }
  el = null;
  return txt;
};
const f = Element.prototype.removeChild;
Element.prototype.removeChild = function () {
  try {
    return f.apply(this, arguments);
  } catch (e) {
    if (e.message.indexOf('is not a child of this node') >= 0) {
      const removeChildErrors = JSON.parse(localStorage.getItem('removeChildErrors')) || [];
      removeChildErrors.push([
        document.getHTML(this, true),
        document.getHTML(arguments[0], true),
      ]);
      localStorage.setItem(
        'removeChildErrors',
        JSON.stringify(removeChildErrors),
      );
    }
    throw e;
  }
};
