/* eslint-disable */

import requirePropFactory from '@mui/utils/requirePropFactory';

const Images = {
  bg: require('../images/bg.jpg'),
  logo: require('../images/logo.png'),
  isologo: require('../images/isologo.png'),
  grayLogo: require('../images/grayLogo.svg'),
  laptop: require('../images/laptop.jpg'),
  users: require('../images/users.jpg'),
  areas: require('../images/areas.jpg'),
  empty: require('../images/woman-empty.png'),
  manEmpty: require('../images/man-empty.png'),
  box: require('../images/box-login.jpg'),
  userPlaceholder: require('../images/placeholder-user.png'),
  customIcon: require('../images/custom-icon.svg'),
  image: require('../images/image.jpg'),
  emailClosed: require('../images/email-closed.jpg'),
  emailOpen: require('../images/email-open.jpg'),
  testimonial1: require('../images/testimonial-c.png'),
  testimonial2: require('../images/testimonial-b.png'),
  testimonial3: require('../images/testimonial-a.png'),
  error: require('../images/error.png'),
  templates: require('../images/plantillas.svg'),
  rules: require('../images/reglas.svg'),
  support: require('../images/support.svg'),
  automation: require('../images/automation.svg'),
  sequences: require('../images/secuencias.svg'),
  properties: require('../images/propiedades.svg'),
  users2: require('../images/users.svg'),
  like: require('../images/like.png'),
  favIcon: require('../images/favicon.ico'),
  favIconUnread: require('../images/favicon-un.ico'),
  visa: require('../images/cards/visa.svg'),
  master: require('../images/cards/master.svg'),
  amex: require('../images/cards/amex.svg'),
  mailSent: require('../images/mail-sent.svg'),
  bodyWspFail: require('../images/bodies/fail/wsp-fail.svg'),
  bodyIgFail: require('../images/bodies/fail/ig-fail.svg'),
  bodyFbFail: require('../images/bodies/fail/fb-fail.svg'),
  bodyFbOk: require('../images/bodies/ok/fb-ok.svg'),
  bodyIgOk: require('../images/bodies/ok/ig-ok.svg'),
  bodyWspOk: require('../images/bodies/ok/wsp-ok.svg'),
  changeUser: require('../images/dialog/changeUser.svg'),
  deleteConvo: require('../images/dialog/deleteConvo.svg'),
  macMicDisabled: require('../images/icons/mac-mic-disabled.ico'),
  winMicDisabled: require('../images/icons/win-mic-disabled.ico'),
  lock: require('../images/icons/lock.png'),
  integrationSeePlans: require('../images/integrations-see-plans.svg'),
  transferConvos: require('../images/transferConvos.svg'),
  successfulTransference: require('../images/successfulTransference.svg'),
  batchSendNoSends: require('../images/phoneAndMessages.svg'),
  newWhatsapp: require('../images/dialog/newWhatsapp.svg'),
  whatsappHelp: require('../images/help/whatsapp_help.svg'),
  messengerHelp: require('../images/help/messenger_help.svg'),
  instagramHelp: require('../images/help/instagram_help.svg'),
  emailHelp: require('../images/help/email_help.svg'),
  userManualHelp: require('../images/help/user_manual_help.svg'),
  userNewConversation: require('../images/userNewConversation.svg'),
};

export default Images;
