export default {
  Automation: 'Automation',
  'Create message': 'Create message',
  Create: 'Create',
  'No automation help':
    'Send messages automatically to your clients, even when your business is closed, assigning the corresponding hours and days.',
  'No automations': 'There are no automated messages.',
  'Automation help': 'Automatically send messages to your customers',
  'Automatic messages': 'Automatic messages',
  'Automatic messages help':
    'Automatically send messages to your customers when your business is closed, assigning the corresponding hours and days.',
  Message: 'Message',
  'Message help':
    'Write the message that will be shown to those who write to your accounts at the scheduled times.',
  'You have not yet written a message': 'You have not yet written a message',
  'Edit message': 'Edit message',
  'Save message': 'Save message',
  Programming: 'Programming',
  'Prograaming help': 'Schedule the times when you will send the message.',
  'Schedule time': 'Schedule time',
  'Schedule message': 'Schedule message',
  'Schedule message help':
    'Do you want your message to be sent only at specific times?',
  'Time zone': 'Time zone',
  'Next day': 'Next day',
  Since: 'Since',
  From: 'From',
  Days: 'Days',
  'Add schedule': 'Add schedule',
  'Active message': 'Active message',
  'Edit scheduled times': 'Edit scheduled times',
  Sunday: 'Sunday',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  day1: 'Monday',
  day2: 'Tuesday',
  day3: 'Wednesday',
  day4: 'Thursday',
  day5: 'Friday',
  day6: 'Saturday',
  day7: 'Sunday',
  letterSunday: 'S',
  letterMonday: 'M',
  letterTuesday: 'T',
  letterWednesday: 'W',
  letterThursday: 'T',
  letterFriday: 'F',
  letterSaturday: 'S',
  'Delete message': 'Delete message',
  'Days and hours': 'Days and hours',
  'Instant delivery': 'Instant delivery',
  'Add automatic message': 'Add automatic message',
  'Update automatic message': 'Update automatic message',
  'Schedule your automation': 'Schedule your automation',
  Optional: '(Optional)',
  'Delete automated message': 'Delete automated message',
  Delete_message_help: 'This action can not be undone.',
  'Please write your message here': 'Please write your message here.',
  InformationInstantDelivery:
    'It will get sent automatically after 10 seconds of receiving a new conversation into Uniboxi. The automated message will not be shown to conversations that are already in Uniboxi.',
  InformationScheduleDays:
    ' It will get sent when receiving a message during the scheduled time. If someone writes multiple times during the scheduled time, the automated message will not be shown more than once every 24 hours.',
  'Select the type of message you want to create': 'Select the type of message you want to create',
  InstantMessageDescription: 'These messsages activate when a new contact initiates a conversation with your business. They are mainly used to welcome new clients or to let them know that you will be with them in minutes.',
  ProgrammedMessageDescription: 'Choose the day and time to activate a particular message to all incoming messages that your company receives. These are great for messages after office hours or on the weekends.',
  'Message type: ': 'Message type: ',
  'Programmed response': 'Programmed response',
  'Instant response': 'Instant response',
  'Messages for weekends or out of office.': 'Messages for weekends or out of office.',
  'Welcome, initial greeting, first autoresponder, etc.': 'Welcome, initial greeting, first autoresponder, etc.',
  'Welcome messages for new conversations.': 'Welcome messages for new conversations.',
  'Create autoreply': 'Create autoreply',
  'Update autoreply': 'Update autoreply',
  'Deactivate message': 'Deactivate message',
  'Activate message': 'Activate message',
};
