export default {
  'General adjustments': 'Ajustes generales',
  'General information': 'Información general',
  Plan: 'Plan',
  'Plan / Integrations': 'Plan / Integraciones',
  Payment: 'Pagos',
  Notifications: 'Notificaciones',
  'Add photo': 'Agregar foto',
  'Change photo': 'Cambiar foto',
  'Change email': 'Cambiar email',
  'Current email': 'Email actual',
  'New email': 'Nuevo email',
  'Change password': 'Cambiar contraseña',
  'Current password': 'Contraseña actual',
  'New password': 'Nueva contraseña',
  'Repeat new password': 'Repetir nueva contraseña',
  'Change language': 'Cambiar idioma',
  'The changes have been saved': 'Los cambios se han guardado correctamente.',
  'Create User': 'Agregar usuario',
  'First name': 'Nombre',
  'Last name': 'Apellido',
  Email: 'Correo electrónico',
  Phone: 'Teléfono',
  'Phone number': 'Teléfono',
  Role: 'Rol',
  'New user': 'Nuevo usuario',
  Save: 'Guardar',
  User: 'Usuario',
  Manager: 'Manager',
  Admin: 'Administrador',
  'Main admin': 'Administrador Principal',
  'Main admin abilities': 'Administrador con capacidad para eliminar y modificar administradores, otorgado por Uniboxi.',
  'Admin abilities': 'Acceso a todas las funcionalidad administrativas y de gestión de la cuenta.',
  'Manager abilities':
    'Todos los permisos del admin, excepto agregar usuarios, upgrade y downgrade de planes o exportar.',
  'Standard abilities': 'Acceso a todas las funcionalidades de comunicación dentro de Uniboxi.',
  'Personal information': 'Información personal',
  Contact: 'Contacto',
  Security: 'Seguridad',
  'Company information': 'Información de empresa',
  'Enter this link, and copy the key with name': 'Ingrese en este link, y copie la clave con nombre',
  'Date and Language': 'Fecha e idioma',
  'Date format': 'Formato de fechas',
  'Member since': 'Miembro desde',
  'Password changed': 'Contraseña cambiada con éxito',
  'Invalid password': 'Error al cambiar la contraseña',
  'DD/MM/YYYY': 'DD/MM/AAAA',
  'MM/DD/YYYY': 'MM/DD/AAAA',
  'Current plan': 'Plan actual',
  'Loading user': 'Cargando usuario',
  'Integrated channels': 'Canales integrados',
  'Free trial': 'Prueba gratis',
  standard: 'Estándar',
  pro: 'Pro',
  'days left': 'días restantes',
  'WhatsApp messages': 'Mensajes de WhatsApp',
  'If you want to continue using Uniboxi after this period, you can choose the plan that best suits your needs':
    'Si desea continuar utilizando Uniboxi luego de este período, puede elegir el plan que mejor se adecúe a sus necesidades:',
  'I want to be notified by mail every time I receive a new message':
    'Quiero ser notificado por correo cada vez que que reciba un nuevo mensaje.',
  'Monthly payment': 'Pagos mensuales',
  'Annual payment': 'Pagos anuales',
  'Per user, per month': 'Por usuario, por mes',
  'Channels to integrate': 'Canales a integrar',
  Unlimited: 'ilimitado',
  'Multichannel templates': 'Plantillas multicanal',
  'Automated sequences': 'Secuencias automatizadas',
  'Priority support': 'Soporte prioritario',
  'I want this plan': 'Quiero este plan',
  'Messages left': 'mensajes restantes',
  'Messages used': 'Mensajes usados',
  'Payment information': 'Información de pago',
  'Billing information': 'Información de facturación',
  Bills: 'Facturas',
  'Add card': 'Agregar tarjeta',
  Update: 'Actualizar',
  'Payment made': 'Pago realizado',
  'See detail': 'Ver detalle',
  'Edit card': 'Edit tarjeta',
  Number: 'Número',
  'Security code': 'Código de seguridad',
  'Expiration date': 'Fecha de vencimiento',
  'Use this card as default': 'Usar esta tarjeta como predeterminada',
  'Standard plan': 'Plan standard',
  'Pro plan': 'Plan pro',
  Address: 'Dirección',
  City: 'Ciudad',
  'Zip code': 'Codigo postal',
  Country: 'País',
  'Whatsapp messages per user': 'Mensajes de Whatsapp por usuario',
  'email in use': 'Correo electrónico ya está en uso. Por favor ingresa otro correo.',
  'email in use in other company':
    'Este correo ya está asociado a otra cuenta de Uniboxi. Por favor usar otro correo.',
  'email in use in the company':
    'Este correo ya está asociado a un usuario de la empresa. Por favor dirígete a la página de Usuarios y búscalo. En caso que está inactivo, lo puedes reactivar ahí mismo.',
  'invalid email': 'Formato de correo electrónico inválido. Por favor revíselo.',
  'Field name': 'Nombre',
  'Field type': 'Tipo de campo',
  'Text field': 'Campo de texto',
  'text field': 'Campo de texto',
  'Number field': 'Campo de número',
  'number field': 'Campo de número',
  'Date field': 'Campo de fecha',
  'date field': 'Campo de fecha',
  'Select field': 'Campo de opciones',
  'select field': 'Campo de opciones',
  'Multiline text field': 'Campo de texto multilínea',
  'multiline_text field': 'Campo de texto multilínea',
  'Fixed field': 'Campo de opciones',
  'fixed field': 'Campo de opciones',
  'There are no inactive properties at this time': 'No hay propiedades inactivas en este momento.',
  Options: 'Opciones',
  'Add option': 'Agregar opción',
  'Option name': 'Nombre de la opción',
  'Uniboxi balance': 'Saldo Uniboxi',
  'Add funds': 'Agregar fondos',
  InformationUniboxiFunds:
    'Los fondos Uniboxi te permiten enviar plantillas preaprobadas en WhatsApp (plan Pro) y enviar mensajes tradicionales en WhatsApp cuando se termine el límite mensual de la cuenta.',
  InformationPaidMessages:
    'Whatsapp cobra un pequeño monto cada vez que una plantilla preaprobada se utiliza. Este monto que se visualiza acá muestra el balance disponible para ser repartido entre todos los usuarios de la cuenta.',
  'Ends in': 'Finaliza en',
  'End of period': 'Término del período',
  'Pre-approved WhatsApp messages': 'Mensajes preaprobados de WhatsApp',
  'Contract plan': 'Contratar plan',
  'You are about to purchase your STANDARD plan': 'Está a punto de contratar su plan ESTANDAR.',
  'You are about to purchase your PRO plan': 'Está a punto de contratar su plan PRO',
  'Fill in the details below to complete the purchase':
    'Completa los datos a continuación para completar la compra.',
  'Users quantity': 'Cantidad de usuarios',
  'Select the number of users that will use uniboxi in your business. You can add or remove users at any time you want':
    'Selecciona la cantidad de usuarios que utilizarán uniboxi en tu negocio. Podrás agregar o quitar usuarios en el momento que lo desees.',
  'Order summary': 'Resumen de pedido',
  'Standard plan x': 'Plan estándar x {{quantity}} ',
  'Pro plan x': 'Plan pro x {{quantity}} ',
  user: 'usuario',
  users: 'usuarios',
  'Monthly fee': 'Cuota mensual',
  Taxes: 'Impuestos',
  'Order total': 'Total de pedido',
  'Payment details': 'Detalles de pago',
  'Available means of payment': 'Medios disponibles',
  'Card number': 'Número de tarjeta',
  'Name on card': 'Nombre en tarjeta',
  CCV: 'CCV',
  'Complete purchase': 'Completar compra',
  'Thanks for your purchase': 'Gracias por su compra',
  'Thank you for hiring Uniboxi. You can now use and enjoy all its functions for your business':
    'Gracias por contratar Uniboxi. Ya puedes hacer uso y disfrutar de todas sus funciones para tu negocio.',
  'Add funds to be able to send WhatsApp messages through pre-approved templates':
    'Agregue fondos para poder enviar mensajes de Whatsapp a través de plantillas preaprobadas.',
  'Current balance': 'Saldo actual',
  'messages available': 'mensajes disponibles',
  'Other amount': 'Otra cantidad',
  Amount: 'Monto',
  messages: 'mensajes',
  'Minimun amount': 'Cantidad mínima $5',
  'Twilio cost help':
    'Twilio, la compañía que da acceso a la plataforma de WhatsApp, cobra un US$0.0083 por cada plantilla preaprobada utilizada. Uniboxi funciona como intermediario y no cobra un monto adicional a este valor establecido por Twilio. Uniboxi sólo retiene un 2.9% + 30 centavos que se utilizan para pagar el cobro por uso de la tarjeta de crédito.',
  '1 user is being added to your PLAN worth $ COST per month':
    'Se está agregando 1 usuario a su {{plan}} por un valor de $ {{cost}} por mes.',
  'Proration adjustment': 'Ajuste prorrateo',
  'It will be charged only in proportion to the days remaining for the next summary':
    'Se cobrara solamente en proporción a los días faltantes para el próximo resumen.',
  lastUserDefaultRule:
    'No es posible desactivar al único usuario de la regla de asignación por defecto. Por favor <2>agregar a otro usuario a la regla</2> e intenta nuevamente.',
  lastUserRule:
    'No es posible desactivar al único usuario de la regla de asignación {{ruleName}}. Por favor <2>agregar a otro usuario a la regla o eliminela</2> e intenta nuevamente.',
  'Create default templates': 'Crear plantillas por defecto',
  Confirmed: 'Confirmado',
  Yes: 'Sí',
  roleError: 'Debe haber al menos un usuario admin',
  Timezone: 'Zona horaria',
};
