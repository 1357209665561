import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import 'moment/locale/es';
import enResources from './locales/en/en';
import esResources from './locales/es/es';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  ...enResources,
  ...esResources,
};

const init18n = () => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      // the translations
      // (tip move them in a JSON file and import them,
      // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
      resources,
      keySeparator: false,
      fallbackLng: 'es',

      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
      react: {
        useSuspense: true,
        nsMode: 'default',
      },
    });
};

export default init18n;
