import { gql } from '@apollo/client';

const LOG_FRONT_ERROR = gql`
  mutation LogFrontError(
    $session: String!
    $jwt: String!
    $errorMessage: String!
    $errorStack: String!
    $removeChildErrors: String
  ) {
    logFrontError(
      errorMessage: $errorMessage
      errorStack: $errorStack
      jwt: $jwt
      session: $session
      removeChildErrors: $removeChildErrors
    ) {
      id
      session
    }
  }
`;

export default LOG_FRONT_ERROR;
