// @flow

import React, { Component } from 'react';

import { Switch, Route } from 'react-router-dom';

import PublicRouter from './components/Router';
import i18n from './i18n';

type Props = {};

type State = {
  i18nReady: boolean,
};

class Routes extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      i18nReady: false,
    };
  }

  componentDidMount() {
    i18n();
    this.setState({ i18nReady: true });
  }

  render() {
    const { i18nReady } = this.state;

    return i18nReady ? (
      <Switch>
        <Route path="/" component={PublicRouter} />
      </Switch>
    ) : (
      <div />
    );
  }
}

export default Routes;
