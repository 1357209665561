export default {
  Messages: 'Messages',
  Posts: 'Posts',
  'See profile in facebook': 'See profile in Facebook',
  'See profile in instagram': 'See profile in Instagram',
  'No contact selected': 'No contact selected',
  Information: 'Information',
  Block: 'Block',
  'Report contact': 'Report contact',
  'Search in': 'Search in',
};
