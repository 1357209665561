export default {
  'Save changes': 'Save changes',
  'Add Google Tag': 'Measure in Google Analytics button statistics',
  'Please insert your GTM Id': 'Add the Measurement Id of your Google Analytics account (GA4).',
  'Please insert your GTM Id 2': 'You must go to Google Analytics> Admin> Data Flows (Data Streams).',

  'GTM help': 'Once you enter the GA4 Measurement Id, every time someone clicks on any of the buttons (WhatsApp, Messenger or Instagram) an event will be created and you will be able to measure the conversions generated on your site Web.',

  'Web button': 'Web button',
  'No channels available': 'No channels available',
  'Integrated channel': 'Integrated channel',
  'Other number': 'Other number',

  'Other account': 'Other account',
  'Web buttons': 'Web buttons',
  'Web buttons subtitle create': 'Create a contact button for your website. It can be multichannel or only for WhatsApp.',
  'Add button': 'Add button',
  OtherNumberTooltip: 'You can use a WhatsApp number that you do not have connected to Uniboxi. You just need to enter the number in the correct format like the following example: +5291234567',
  OtherAccountMessengerTooltip: 'You can use an account that you have not connected to Uniboxi on your button. You just need to copy your url in the correct format as shown below: m.me/pagename',
  OtherAccountInstagramTooltip: 'You can use an account that you have not connected to Uniboxi on your button. You just need to copy your url in the correct format as shown below: instagram.com/youraccount',
  'Choose button type': 'Choose button type',

  youraccount: 'youraccount',
  pagename: 'pagename',

  'Website code': 'Website code',

  // widgets table
  Type: 'Type',
  Clicks: 'Clicks',
  WhatsappButton: 'Whatsapp button',
  MultichannelButton: 'Multichannel button',
  'Code for web': 'Code for web',
  NoWidgetsFound: "You haven't added buttons for your website yet.",

  'Multichannel button': 'Multichannel button',
  'Whatsapp button': 'Whatsapp button',
  'multichannel-type': 'Multichannel button',
  'whatsapp-type': 'Whatsapp button',
  ClicksInformation: 'Last 30 days',


  // export widget
  'Export your button': 'Export your button',
  'Enter your email to finish the process': 'Please enter your email to receive the code for your website',
  'You must enter a valid email': 'You must enter a valid email',
  'Email is required': 'Email is required',
  'Get code': 'Get code',
  'Your email': 'Your email',

  // delete widget
  confirm_delete_title: 'Are you sure you want to delete the button?',
  confirm_delete_subtitle: 'It will no longer appear on any web page where you have inserted it.',

  // build widget title
  build_widget_title: 'Free WhatsApp button for your website',
  build_widget_subtitle: 'Create a WhatsApp contact button for your website in seconds and start receiving messages. Totally free.',
  'Start now': 'Start now',

  // color picker
  'Header color': 'Header color',
  'Use on button': 'Use on button',
  Background: 'Background',
  Restore: 'Restore',
  Text: 'Text',
  Light: 'Light',
  Dark: 'Dark',
  '(default)': '(default)',

  // header input
  'Header text': 'Header text',

  // numbers section
  'Number is required': 'Number is required',
  Number: 'Number',
  'Choose the text and color': 'Choose the text and color',
  Personalization_subtitle: 'Choose the texts and colors with which you will communicate with your customers',
  'Phone number': 'Phone number',
  'Page name': 'Page name',
  'Account name': 'Account name',
  'Area name': 'Area name',

  // phone input
  Phone_input_subtitle: 'Add one or more phone numbers, dedicated to different areas of your business. Example: +5291234567',
  'Add number': 'Add number',
  'Add account': 'Add account',

  // popup config
  'Advanced options': 'Advanced options',
  popup_config_1: 'This widget will open up automatically after the visitor has visited 1 page of the website and spent at least 20 seconds on it. If you want to change this setting, please modify the variables below. We know that you can get up to ',
  popup_config_2: '50% more messages',
  popup_config_3: 'when this setting is activated',
  Activated: 'Activated',
  'Number of pages': 'Number of pages',
  'Time amount (seconds)': 'Time on webpage (seconds)',

  // position selector
  Position: 'Position',
  position_selector_title: 'You can choose whether you want the window to appear on the left or right hand side of the website',
  Right: 'Right',
  Left: 'Left',


  // preview title
  Preview: 'Preview',
  preview_title_subtitle: 'Interact with the button to see how it will look on your website',

  // testimonials
  testimonialBody: 'We hired Uniboxi because we needed to use Whatsapp for our 5 people customer support team. We can split all the incoming messages among the support team and classify each conversation in different categories. We are now also adding another channel (Messenger) to let our customers contact us directly from Facebook as well.',
  Testimonials: 'Testimonials',

  // know more
  knowMoreTitle: 'Have better conversations with your customers using messaging.',
  knowMoreFirstPoint: 'A simple dashboard to see all your WhatsApp, Instagram and Facebook Messenger messages in one place.',
  knowMoreSecondPoint: "Add your entire sales or support team and split incoming messages between them. Then easily monitor your team's conversations.",
  knowMorethirdPoint: 'Close more deals by sending the quote and follow up automatically in days. Start by integrating a WhatsApp number, Instagram or Messenger account.',
  'Know more': 'Know more',


  // get code widget
  GetCodeWidgetTitle: 'Whatsapp button is ready for use.',
  GetCodeWidgetSubtitle: "Copy the following code and paste it on every page of your website before closing the </body> tag. If you don't know how to do it, send the code to your webmaster.",
  YourCodeTitle: 'Your code',
  YourCodeSubtitle: "Copy the following code and paste it on every page of your website before closing the </body> tag. If you don't know how to do it, send the code to your webmaster.",
  'Copy to clipboard': 'Copy to clipboard',

  // multichannel input
  'Multichannel subtitle': 'Choose the channels you are going to use on your button.',
  'Multichannel title': 'Add the channels you want to display',


  // errors
  'Number cannot have parenthesis': 'Number cannot have parenthesis',
  'Number cannot have spaces': 'Number cannot have spaces',
  'Number cannot have dashes': 'Number cannot have dashes',
  'Number cannot have plus': 'Number can only have plus sign at the beginning',
  'Only numbers are allowed': 'Only numbers are allowed',
  'A valid number is required': 'A valid number is required',
  'A valid name is required': 'A valid name is required',
  'Page name cannot have spaces': 'Page name cannot have spaces',
};
