/* eslint-disable no-use-before-define */
/* eslint-disable no-console */

import {
  ApolloClient, InMemoryCache, ApolloLink, split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import apolloLogger from 'apollo-link-logger';
import { persistCacheSync, LocalStorageWrapper } from 'apollo3-cache-persist';
import { createUploadLink } from 'apollo-upload-client';
import MutationQueueLink from '@adobe/apollo-link-mutation-queue';
import { getMainDefinition, relayStylePagination } from '@apollo/client/utilities';
import { createConsumer } from '@rails/actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';

const AUTH_HEADERS = 'uniboxi-auth';

const createActionCableLink = () => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/cable`;

  const cable = createConsumer(url);
  const cableUrl = cable.url;

  Object.defineProperty(cable, 'url', {
    get() {
      const authHeadersString = localStorage.getItem(AUTH_HEADERS);
      let newUrl = cableUrl;
      if (authHeadersString) {
        const authHeaders = JSON.parse(authHeadersString);
        newUrl = `${cableUrl}?access-token=${authHeaders['access-token']}&client=${authHeaders.client}&uid=${encodeURIComponent(authHeaders.uid)}`;
      }

      return newUrl;
    },
  });

  return new ActionCableLink({ cable, connectionParams: () => ({ cualquiera: 'hola' }) });
};

let actionCableLink = createActionCableLink();

function reconnectActionCableLink() {
  actionCableLink.cable.disconnect();
  actionCableLink = createActionCableLink();
}

const middlewareLink = new ApolloLink((operation, forward) => {
  const { operationName } = operation;

  let authHeaders = {};

  if (!['userLogin',
    'logout',
    'register',
    'confirmEmail',
    'resendConfirmation',
    'createPotential',
    'updateWidgetClicks',
    'userUpdatePasswordWithToken',
  ].includes(operationName)) {
    authHeaders = JSON.parse(localStorage.getItem(AUTH_HEADERS) || '{}');
  }

  operation.setContext(({ headers }) => ({
    headers: {
      ...authHeaders,
      ...headers,
    },
  }));

  return forward(operation);
});

const afterwareLink = new ApolloLink((operation, forward) => forward(operation).map((response) => {
  const context = operation.getContext();
  const {
    response: { headers } = {},
  } = context;

  if (headers) {
    const accessToken = headers.get('access-token');

    if (accessToken) {
      const authHeaders = {
        'access-token': accessToken,
        client: headers.get('client'),
        uid: headers.get('uid'),
      };

      const previousAuthHeaders = JSON.parse(localStorage.getItem(AUTH_HEADERS) || '{}');

      if (JSON.stringify(authHeaders) !== JSON.stringify(previousAuthHeaders)) {
        console.log(JSON.stringify(authHeaders), JSON.stringify(previousAuthHeaders));
        localStorage.setItem(AUTH_HEADERS, JSON.stringify(authHeaders));
        reconnectActionCableLink();
      }
    }
  }

  return response;
}));

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({
      message, locations, path, extensions,
    }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);

      if (extensions && extensions.code === 'AUTHENTICATION_ERROR') {
        localStorage.clear();
        client.clearStore().then(() => window.location.replace('/login'));
      }
    });
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_ENDPOINT || ''}/graphql`,
});

const terminatingLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  (operation, forward) => actionCableLink.request(operation, forward),
  httpLink,
);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        usersMessagesData: relayStylePagination(),
        usersLeadKindsData: relayStylePagination(),
        userActiveClientsCount: relayStylePagination(),
        userResponseTimes: relayStylePagination(),
        batchSends: relayStylePagination(),
      },
    },
    Message: {
      fields: {
        status: {
          merge(existing, incoming) {
            const statusOrder = [
              'queued',
              'sending',
              'sent',
              'delivered',
              'read',
              'undelivered',
              'failed',
              'delivery_unknown',
            ];
            if (statusOrder.indexOf(incoming) > statusOrder.indexOf(existing)) return incoming;
            if (existing) return existing;
            return incoming;
          },
        },
      },
    },
  },
});

persistCacheSync({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
});

const client = new ApolloClient({
  link: ApolloLink.from([
    new MutationQueueLink(),
    errorLink,
    apolloLogger,
    middlewareLink,
    afterwareLink,
    terminatingLink,
  ]),
  cache,
});

export default client;
