export default {
  Companies: 'Companies',
  'Company ID': 'Company ID',
  Owner: 'Owner',
  'Owner email': 'Owner email',
  'Payment method': 'Payment method',
  Status: 'Status',
  Users: 'Users',
  'Available users': 'Available users',
  'Signup date': 'Signup date',
  Integrations: 'Integrations',
  'Ammount of Messenger messages': 'Ammount of Messenger messages',
  'Ammount of Whatsapp messages': 'Ammount of Whatsapp messages',
  Actions: 'Actions',
  See: 'See',
  manual: 'Manual',
  stripe: 'Stripe',
  inactive: 'Inactive',
  active: 'Active',
  trial: 'Trial',
  Processing: 'Processing',
  Accepted: 'Accepted',
  Rejected: 'Rejected',
  Deleted: 'Deleted',
  'No number': 'No number',
  'Pre-approved templates': 'Pre-approved templates',
  'Create pre-approved template': 'Create pre-approved template',
  'Begin of period': 'Begin of period',
  'Ammount of WhatsApp pre-approved templates': 'Ammount of WhatsApp pre-approved templates',
  'WhatsApp messages cost': 'WhatsApp messages cost',
  'WhatsApp pre-approved templates cost': 'WhatsApp pre-approved templates cost',
  'Total WhatsApp cost': 'Total WhatsApp cost',
  'Twilio SID number': 'Twilio SID number',
  'Channel type integrated': 'Channel type integrated',
  'Transfer conversations': 'Transfer conversations',
};
