export default {
  'Payment required': 'Payment required',
  'Your trial period will end soon': 'Your trial period will end soon',
  'We inform you that your free trial period ends in 5 days. To continue using Uniboxi without interruptions, we invite you to choose a plan':
    'We inform you that your free trial period ends in 5 days. To continue using Uniboxi without interruptions, we invite you to choose a plan.',
  'You are reaching the limit of WhatsApp messages':
    'You are reaching the limit of WhatsApp messages',
  'Wed like to let you know that the account is running low on available WhatsApp messages. The account admin must upgrade the plan':
    "We'd like to let you know that the account is running low on available WhatsApp messages. The account admin must upgrade the plan.",
  'The account has reached the limit of WhatsApp messages':
    'The account has reached the limit of WhatsApp messages',
  'No whatsapp messages left description':
    "The account will continue receiving incoming WhatsApp messages. However, the messages won't be able to be answered until the the next billing period or when the account has been upgraded to a new plan.",
  'Inactive account': 'Inactive account',
  'Please make a payment to continue using Uniboxi. To renew or sign up for a new subscription, please write to admin@uniboxi.com':
    'Please make a payment to continue using Uniboxi. To renew or sign up for a new subscription, please write to admin@uniboxi.com',
  'See plans': 'See plans',
  'The account has run out of WhatsApp messages and the account admin must buy more credits':
    'The account has run out of WhatsApp messages and the account admin must buy more credits.',
  'Update sort clients title': 'We have updated the main Board',
  'Update sort clients description':
    'From now on, all conversations on the board will be automatically displayed according to the last message received or sent. Therefore it will no longer be possible to sort the cards manually within the category since they will always be sorted according to the date of the last message.',
  'Uniboxi keeps accepting new WhatsApp messages but you can not reply to them until the account has new credits':
    'Uniboxi keeps accepting new WhatsApp messages but you can not reply to them until the account has new credits.',
};
