import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router';
import { withApollo } from '@apollo/client/react/hoc';
import { useMutation } from '@apollo/client';
import StackTrace from 'stacktrace-js';

import Colors from '../../themes/Colors';
import Images from '../../themes/Images';
import LOG_FRONT_ERROR from '../../queries/mutation/logFrontError';

const Fallback = ({ t, history, error }) => {
  const [logFrontError] = useMutation(LOG_FRONT_ERROR);

  const handleBack = () => {
    history.goBack();
  };

  const handleHome = () => {
    history.replace('/board');
  };

  useEffect(() => {
    if (!error) {
      return;
    }

    const auth = localStorage.getItem('uniboxi-auth');
    const session = localStorage.getItem('session');
    const removeChildErrors = localStorage.getItem('removeChildErrors');
    localStorage.removeItem('removeChildErrors');

    const callback = (stackframes) => {
      const stringifiedStack = stackframes.map(sf => sf.toString()).join('\n');
      // TODO Preguntar a tincho ver como loggear front error
      logFrontError({
        variables: {
          errorMessage: `${window.location.href}: ${error.message}`,
          errorStack: stringifiedStack,
          jwt: auth,
          session,
          removeChildErrors,
        },
      });
    };

    const errback = function (err) {
      console.log(err.message);
    };

    StackTrace.fromError(error).then(callback, errback);
  }, [error, logFrontError]);

  return (
    <div
      style={{
        width: '100%',
        boxSizing: 'border-box',
        minHeight: '100vh',
      }}
    >
      <Grid
        style={{
          padding: 20,
          minHeight: '100vh',
          background: `linear-gradient(180deg, ${Colors.bgGray} 50%, ${Colors.primary} 50%)`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        container
      >
        <Grid item xs={12} sm={11} md={10} lg={8} xl={8}>
          <Card
            elevation={0}
            style={{
              borderRadius: 20,
              boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.20)',
            }}
          >
            <Grid container style={{ padding: 30, minHeight: '65vh' }}>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  alignItems: 'start',
                  paddingLeft: 35,
                }}
              >
                <div
                  style={{
                    marginTop: '25%',
                    fontSize: 48,
                    color: Colors.coal,
                    fontWeight: '700',
                    marginBottom: 10,
                  }}
                >
                  {t('Oops')}
                </div>
                <div
                  style={{
                    fontSize: 20,
                    color: Colors.coal,
                    marginBottom: 15,
                    fontWeight: '700',
                  }}
                >
                  {t('We cant find the page you were looking for')}
                </div>
                <div
                  style={{
                    fontSize: 14,
                    color: Colors.buttonGray,
                    fontWeight: '600',
                  }}
                >
                  {t('You can go to the')}
                  <span
                    onClick={() => handleBack()}
                    style={{ color: Colors.primary, cursor: 'pointer' }}
                    aria-hidden
                  >
                    {t('last page')}
                  </span>
                  {' '}
                  {t('or direct to')}
                  <span
                    onClick={() => handleHome()}
                    style={{ color: Colors.primary, cursor: 'pointer' }}
                    aria-hidden
                  >
                    {t('main dashboard')}
                  </span>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  alt="error"
                  src={Images.error}
                  style={{ maxWith: 200, height: 350, margin: '20px 0' }}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default withTranslation()(withApollo(withRouter(Fallback)));
