export default {
  'Create template': 'Crear plantilla',
  'Created by': 'Credo por',
  'Creation date': 'Fecha de creación',
  Channel: 'Canal',
  Channels: 'Canales',
  'Choose the channels you wish to apply this template':
    'Elija los canales que desea aplicar la plantilla.',
  'Shared with everyone': 'Compartido con todos',
  'Assign channels': 'Asignar canales',
  'Template visible only to myself.': 'Plantilla visible sólo para mí',
  'Template visible to all users of the account.': 'Plantilla visible para todos los usuario de la cuenta.',
  'Template content': 'Contenido de plantilla',
  'No templates': 'Aún no hay plantillas',
  'No pre-approved templates': 'Aún no hay plantillas preaprobadas',
  'Create a template': 'Cree una plantilla',
  Attachment: 'Adjunto',
  'Edit template': 'Editar plantilla',
  'Edit name': 'Editar nombre',
  'Edit template name': 'Editar nombre de la plantila',
  'Template name': 'Nombre de plantila',
  'Delete template': 'Eliminar plantilla',
  'Missing fields on template': 'Faltan campos en la plantilla',
  'Assigned user name': 'Nombre del usuario asignado',
  'Assigned user email': 'Correo del usuario asignado',
  'Assigned user phone': 'Teléfono del usuario asignado',
  missing_fields_alert:
    'El usuario no tiene asignados {{missingFields}} campos que se usan en la plantilla. Asigne un valor manualmente',
  delete_template_error: 'No se pudo eliminar la plantilla',
  delete_template_help1:
    'No es posible eliminar esta plantilla ya que se está usando en las siguientes secuencias:',
  delete_template_help2:
    'Una vez que la plantilla haya sido eliminada de las secuencias, esta se podrá eliminar.',
  'No templates help':
    'Responde más rápido creando plantillas de mensajes para usar en todos tus canales. ',
  'First Name': 'Nombre',
  'Last Name': 'Apellido',
  Standard: 'Estándar',
  'No plan': 'Sin plan',
  'Pre-approved': 'Preaprobadas',
  'No pre-approved templates help':
    'Las plantillas preaprobadas pueden ser usadas para enviar mensajes en Whatsapp una vez finalizado el límite de 24 horas desde el último mensaje del cliente. De acuerdo a las políticas de Whatsapp.',
  'Request pre-approved template': 'Solicitar plantilla preaprobada',
  'Pre-approved template requested': 'Plantilla preaprobada solicitada',
  'You want to request a pre-approved template?': 'Desea solicitar una plantilla preaprobada?',
  'Our support service will contact you immediately to complete the operation':
    'Nuestro servicio de soporte se pondrá en contacto de inmediato para completar la operacion.',
  'Your request has been sent successfully': 'Su solicitud ha sido enviada con éxito.',
  'Full name': 'Nombre completo',
  'Insufficient funds for pre-approved messages':
    'Fondos para plantillas preaprobadas insuficientes.',
  'Click to': 'Click a continuacion para',
  'No pre-approved templates included':
    'Su plan no incluye el uso de plantillas preaprobadas. El admin de la cuenta puede activar esto haciendo el ',
  'upgrading the plan': 'cambio de plan',
  'Pre-approved templates help':
    'Las plantillas preaprobadas dependen de la aprobación de Whatsapp. Este proceso puede tardar un aproximado de hasta 24 hs.',
  'Support mail': 'admin@uniboxi.com',
  'Update template': 'Editar plantilla',
  Spanish: 'Español',
  English: 'Inglés',
  Language: 'Idioma',
  Category: 'Categoría',
  'Create template pre-approved': 'Crear plantilla pre-aprobada',
  'Create template standard': 'Crear plantilla estándar',
  'To approve your template, WhatsApp requests that you provide an example of the following variables used.':
    'Para aprobar su plantilla, WhatsApp solicita que se brinde un ejemplo de las siguientes variables a utilizadas.',
  'Eg.: If you are using the Name field, write Peter or Alice as an example.':
    'Ej: Si estás usando el campo Nombre, escribe como ejemplo Luis o Marta.',
  firstName: 'Nombre',
  lastName: 'Apellido',
  email: 'Correo electrónico',
  number: 'Teléfono',
  assignedUserName: 'Nombre del usuario asignado',
  assignedUserEmail: 'Correo electrónico del usuario asignado',
  assignedUserPhone: 'Teléfono del usuario asignado',
  'The message template name field is limited to 512 characters.':
    'El campo del nombre de la plantilla del mensaje está limitado a 512 caracteres',
  'The message template name can only contain lowercase alphanumeric characters. Use':
    'El nombre de la plantilla de mensajes sólo puede contener caracteres alfanuméricos. Utilice',
  'The message template content field is limited to 1024 characters':
    'El campo de contenido de la plantilla del mensaje está limitado a 1024 caracteres',
  'invalid variables': 'Estas variables no son válidas',
  'Name in use': 'Ya existe una plantilla con ese nombre',
  Approved: 'Aprobada',
  Pending: 'Pendiente',
  Rejected: 'Rechazada',
  Flagged: 'Marcada',
  Disabled: 'Deshabilitada',
  'Your template has been submitted and is awaiting review.': 'Su plantilla ha sido envida y se encuentra a la espera de ser revisada.',
  'Your template has been rejected for use on your board.': 'Su plantilla ha sido rechazada para ser utilizada en su tablero.',
  'Your template has been deactivated after spending 7 days in marked status.': 'Su plantilla ha sido desactivada luego de pasar 7 días en estado de marcada.',
  'Your template has been successfully approved.': 'Su plantilla ha sido aprobada con éxito.',
  'Your template has been flagged, please contact support.': 'Tu plantilla ha sido señalada, por favor contacta a soporte.',
  'Message template is being deleted, please use other name': 'Una plantilla con el mismo nombre está siendo eliminada, por favor utilice otro nombre.',
  'Update template pre-approved': 'Editar plantilla pre-aprobada',
  'Update template standard': 'Editar plantilla estándar',
  account_update: 'Actualización de cuenta',
  alert_update: 'Actualización de alertas',
  appointment_update: 'Actualización de citas',
  auto_reply: 'Respuesta automática',
  issue_resolution: 'Resolución de problemas',
  payment_update: 'Actualización de pagos',
  personal_finance_update: 'Actualización de finanzas personales',
  reservation_update: 'Actualización de reservas',
  shipping_update: 'Actualización de envíos',
  ticket_update: 'Actualización de billetes',
  transportation_update: 'Actualización de transporte',
  otp: 'Contraseña de un solo uso',
  utility: 'Utilidad',
  marketing: 'Marketing',
  'Message template components param is missing expected field(s)': 'A la plantilla del mensaje le faltan los campos esperados',
  'Template with that body already exists.': 'Ya existe una plantilla con el mismo contenido.',
  'Whatsapp paid templates description': 'Las plantillas preaprobadas solo son utilizadas en los canales de WhatsApp',
};
