export default {
  Messages: 'Mensajes',
  Posts: 'Posteos',
  'See profile in facebook': 'Ver perfil en Facebook',
  'See profile in instagram': 'Ver perfil en Instagram',
  'No contact selected': 'No hay contacto seleccionado',
  Information: 'Información',
  Block: 'Bloquear',
  'Report contact': 'Reportar contacto',
  'Search in': 'Buscar en',
};
