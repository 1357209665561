export default {
  'Basic reports': 'Reportes básicos',
  Summary: 'Resumen',
  Date: 'Fecha',
  Days: 'Días',
  Weeks: 'Semanas',
  Months: 'Meses',
  User: 'Usuario',
  'New conversations': 'Nuevas conversaciones',
  'Incoming messages': 'Mensajes entrantes',
  'Outgoing messages': 'Mensajes salientes',
  'Incoming and outgoing messages': 'Mensajes entrantes y salientes',
  'Pre-approved whatsapp templates': 'Plantillas preaprobadas Whatsapp',
  'Manually added': 'Agregadas manualmente',
  'Reports by category': 'Reportes por categoría',
  'Number of incoming conversations per category': 'Cantidad de conversaciones entrantes por categoría.',
  'Daily reports': 'Reportes diarios',
  'No information': 'No hay información',
  'Loading Page': 'Cargando Página',
  'Unread conversations': 'Conversaciones no leídas',
  'The number of conversations with unread messages per user is displayed in real time':
    'Se muestra la cantidad de conversaciones con mensajes no leídos por usuario en tiempo real.',
  'Users reports': 'Reportes por usuario',
  'Conversations by category': 'Conversaciones por categoría',
  'Number of incoming cards per category': 'Cantidad de tarjetas entrantes por categoría',
  'Response time': 'Tiempo de respuesta',
  'Check the average response time of your users':
    'Verifique el tiempo promedio de respuesta de sus usuarios.',
  'User name': 'Nombre de usuario',
  'First answer': 'Primera respuesta',
  'Download table': 'Descargar tabla',
  AllFem: 'Todas',
  Categories: 'Categorías',
  'Order by': 'Ordenar por',
  'Less time': 'Menos tiempo',
  'Longer time': 'Mayor tiempo',
  Alphabetically: 'Alfabéticamente',
  'Batch sends on whatsapp': 'Envíos de marketing por WhatsApp',
  'Batch for sends on batch subtitle': 'Estadísticas de los mensajes masivos enviados por WhatsApp durante el período seleccionado.',
  'Base name': 'Nombre base',
  'Sent messages': 'Mensajes enviados',
  Answers: 'Respuestas',
  'Upload date': 'Fecha de carga',
  'Response percentages': 'Porcentaje de respuesta',
  'Average time': 'Tiempo de respuesta (minutos)',
  'Response time subtitle': 'Tiempo promedio en responder a nuevas conversaciones.',
  'Input and output messages per user': 'Mensajes de entrada y salida por usuario',
  'The number of messages sent and received by all channels is considered': 'Se considera la cantidad de mensajes enviados y recibidos por todos los canales.',
  'New conversations per channel': 'Nuevas conversaciones por canal',
  'Detail with the number of new conversations assigned to each user.': 'Detalle con la cantidad de nuevas conversaciones asignadas a cada usuario.',
  'Active conversations per user': 'Conversaciones activas por usuario',
  Conversations: 'Conversaciones',
  'Empty batch send title': 'Envios de marketing por WhatsApp',
  'Empty batch send text 1': 'Tus clientes se encuentran en WhatsApp, envíales mensajes de marketing y aumenta tus ventas. Puedes enviar cientos o miles de mensajes de una sola vez. Escríbenos a',
  'Empty batch send text 2': 'para coordinar el envío.',
  'Number of different conversations each user had during the selected period.': 'Cantidad de conversaciones con clientes únicos para cada usuario.',
  'Need a minimum of 10 conversations': 'Se necesita un mínimo de 10 conversaciones para mostrar la estadística.',
  'Average time 50 percent': 'Top 50%',
  'Average time 60 percent': 'Top 60%',
  'Average time 70 percent': 'Top 70%',
  'Average time below 3 hours': 'Tiempos menores a 3 horas',
  'Average time below 4 hours': 'Tiempos menores a 4 horas',
  'Average time below 8 hours': 'Tiempos menores a 8 horas',
  'All (max. 6 months)': 'Todo (max. 6 meses)',
  'Without information': 'Sin información',
};
