export default {
  General: 'General',
  'Select all': 'Seleccionar todos',
  Starred: 'Favoritos',
  Unread: 'No leidos',
  Tags: 'Etiquetas',
  Propietary: 'Propietario',
  'Search propietary': 'Buscar propietario',
  filter: 'filtro',
  applied: 'aplicado',
  'Create date': 'Fecha de creación',
  Today: 'Hoy',
  Yesterday: 'Ayer',
  'This week (Mon-Sun)': 'Esta semana (Lun-Dom)',
  'This week': 'Esta semana',
  'Last 7 days': 'Últimos 7 días',
  'Last week (Mon - Sun)': 'Semana pasada (Lun-Dom)',
  'Past week': 'Semana pasada (Lun-Dom)',
  'Last 14 days': 'Últimos 14 días',
  'Past 2 weeks': 'Últimas 2 semanas',
  'This month': 'Este mes',
  'Last 30 days': 'Últimos 30 días',
  'Last month': 'Último mes',
  'Past month': 'Mes pasado',
  All: 'Todos',
  Everything: 'Todo',
  'Without tags': 'Sin etiquetas',
  'applied filters': 'Filtros aplicados',
  'applied filter': 'Filtro aplicado',
};
