/* eslint-disable react/react-in-jsx-scope */
import React, {
  createContext, useContext, useReducer,
} from 'react';
import { VARIABLES_ACTION_TYPES, variablesInitialState, variablesReducer } from '../reducers/variables';

export const VariablesContext = createContext({
  variables: {},
  setOnlyStarred: () => null,
  setOnlyUnread: () => null,
  setFilter: () => null,
  setDateFilter: () => null,
  setTagsFilter: () => null,
  setChannelsFilter: () => null,
  setUsersFilter: () => null,
  setTrash: () => null,
  clearFilters: () => null,
});

export const useVariables = () => useContext(VariablesContext);

const useVariablesReducer = () => {
  const [state, dispatch] = useReducer(variablesReducer, variablesInitialState);

  const setOnlyStarred = onlyStarred => dispatch({
    type: VARIABLES_ACTION_TYPES.SET_ONLY_STARRED,
    payload: onlyStarred,
  });

  const setOnlyUnread = onlyUnread => dispatch({
    type: VARIABLES_ACTION_TYPES.SET_ONLY_UNREAD,
    payload: onlyUnread,
  });

  const setFilter = filter => dispatch({
    type: VARIABLES_ACTION_TYPES.SET_FILTER,
    payload: filter,
  });

  const setDateFilter = dateFilter => dispatch({
    type: VARIABLES_ACTION_TYPES.SET_DATE_FILTER,
    payload: dateFilter,
  });

  const setTagsFilter = tagsFilter => dispatch({
    type: VARIABLES_ACTION_TYPES.SET_TAGS_FILTER,
    payload: tagsFilter,
  });

  const setChannelsFilter = channelsFilter => dispatch({
    type: VARIABLES_ACTION_TYPES.SET_CHANNEL_FILTER,
    payload: channelsFilter,
  });

  const setUsersFilter = usersFilter => dispatch({
    type: VARIABLES_ACTION_TYPES.SET_USERS_FILTER,
    payload: usersFilter,
  });

  const setTrash = trash => dispatch({
    type: VARIABLES_ACTION_TYPES.SET_TRASH,
    payload: trash,
  });

  const clearFilters = () => dispatch({
    type: VARIABLES_ACTION_TYPES.CLEAR_FILTERS,
  });

  return {
    state,
    setOnlyStarred,
    setOnlyUnread,
    setFilter,
    setDateFilter,
    setTagsFilter,
    setChannelsFilter,
    setUsersFilter,
    setTrash,
    clearFilters,
  };
};

export const VariablesProvider = ({ children }) => {
  const {
    state,
    setOnlyStarred,
    setOnlyUnread,
    setFilter,
    setDateFilter,
    setTagsFilter,
    setChannelsFilter,
    setUsersFilter,
    setTrash,
    clearFilters,
  } = useVariablesReducer();
  return (
    <VariablesContext.Provider value={{
      variables: state,
      setOnlyStarred,
      setOnlyUnread,
      setFilter,
      setDateFilter,
      setTagsFilter,
      setChannelsFilter,
      setUsersFilter,
      setTrash,
      clearFilters,
    }}
    >
      {children}
    </VariablesContext.Provider>
  );
};
