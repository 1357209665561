import Recorder from 'opus-recorder';
import React, {
  createContext, useState, useContext, useRef, useEffect, useCallback,
} from 'react';

const RecorderContext = createContext({
  startRecorder: () => null,
  stopRecorder: () => null,
  mediaBlobUrl: null,
  clearBlobUrl: () => null,
});

export const useRecorder = () => useContext(RecorderContext);

export const RecorderProvider = ({ children }) => {
  const [mediaBlobUrl, setMediaBlobUrl] = useState('');

  const recorderRef = useRef(new Recorder({ encoderPath: `${window.location.origin}/encoderWorker.min.js` })).current;

  useEffect(() => {
    recorderRef.ondataavailable = (typedArray) => {
      const dataBlob = new Blob([typedArray], { type: 'audio/ogg' });
      setMediaBlobUrl(URL.createObjectURL(dataBlob));
    };
  }, [recorderRef.ondataavailable]);

  const clearBlobUrl = useCallback(() => setMediaBlobUrl(), []);

  const startRecorder = () => {
    clearBlobUrl();
    recorderRef.start();
  };

  const stopRecorder = () => {
    recorderRef.stop();
  };

  return (
    <RecorderContext.Provider value={{
      startRecorder, stopRecorder, mediaBlobUrl, clearBlobUrl,
    }}
    >
      {children}
    </RecorderContext.Provider>
  );
};
