export default {
  Integrations: 'Integrations',
  Reports: 'Reports',
  Tools: 'Tools',
  Stadistics: 'Stadistics',
  Templates: 'Templates',
  Sequences: 'Sequences',
  'Assignment rules': 'Assignment rules',
  'User account': 'User account',
  'Account and preferences': 'Account and preferences',
  Company: 'Company',
  'Prices and plans': 'Prices and planes',
  'Payment history': 'Payment history',
  Account: 'Account',
  'Sign out': 'Sign out',
  'See all': 'See all',
  'Back to home': 'Back to home',
  'Go to dashboard': ' Go to dashboard',
  'Add and delete users': 'Add and delete users',
  'Add and delete areas': 'Add and delete areas',
  'Use pre-defined messages': 'Use pre-defined messages',
  'Automate tracking messages': 'Automate tracking messages',
  'Distribute the conversations in Uniboxi': 'Distribute the conversations in Uniboxi',
  'WhatsApp messages': 'WhatsApp messages',
  Support: 'Support',
  'Contact our support center': 'Email our support team',
  'Web notifications': 'Chrome notifications',
  'Notify me when:': 'Notify me when:',
  'Receive a new message': 'Receive a new message',
  'Only when a new conversation enters Uniboxi': 'Only when a new conversation enters Uniboxi',
  Never: 'Never',
  'Pause web notifications': 'Pause Chrome notifications',
  '15 minutes': '15 minutes',
  '30 minutes': '30 minutes',
  '1 hour': '1 hour',
  '2 hours': '2 hours',
  'Until tomorrow': 'Until tomorrow',
  'Contact us via email': 'Contact us via email',
  'Contact us via WhatsApp': 'Contact us via WhatsApp',
  'Contact us via Instagram': 'Contact us via Instagram',
  'Contact us via Messenger': 'Contact us via Messenger',
  'Complete guide to use Uniboxi': 'Complete guide to use Uniboxi',
  'User guide': 'User guide',
  'Email notifications': 'Email notifications',
  'Notify me if I receive a new message': 'Notify me when I have unread messages',
  'Every 15 minutes': 'Every 15 minutes',
  'Every 5 minutes': 'Every 5 minutes',
  'Every 1 hour': 'Every 1 hour',
};
