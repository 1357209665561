export default {
  default: 'Default rule',
  'Create assignment rule': 'Create assignment rule',
  'Round-Robin': 'Round-Robin',
  'Custom assignment': 'Custom assignment',
  Low: 'Low',
  Medium: 'Medium',
  High: 'High',
  'Create rule': 'Create rule',
  'Assigned total': 'Assigned total',
  'Edit assignment rule': 'Edit assignment rule',
  'Delete assignment rule': 'Delete assignment rule',
  'Save assignment rule': 'Save assignment rule',
  "Default rule can't be edited": "Default rule can't be edited",
  'Please complete the name of the rule': 'Please complete the name of the rule',
  'Please complete the channels of the rule': 'Please complete the channels of the rule',
  'Please complete the users of the rule': 'Please complete the users of the rule',
  'The percentages dont add 100': 'Total assigned percentage must be 100%',
  'Please assign channels': 'Please assign channels',
  'Please assign users': 'Please assign users',
  'No rules help': 'Create custom rules to assign conversations among your users.',
  'All the incoming messages will be assigned evenly among the users': 'All the incoming messages will be assigned evenly among the users.',
  'Create a custom rule to assign some users more messages than others':
    'Create a custom rule to assign some users more messages than others.',
};
