import enIntegrations from './enIntegrations';
import enLogin from './enLogin';
import enTopbar from './enTopbar';
import enDrawer from './enDrawer';
import enAccount from './enAccount';
import enEllipsisPopover from './enEllipsisPopover';
import enTemplates from './enTemplates';
import enRules from './enRules';
import enChat from './enChat';
import enSequences from './enSequences';
import enFilter from './enFilter';
import enDashboard from './enDashboard';
import enNotifications from './enNotifications';
import enAutomation from './enAutomation';
import enReports from './enReports';
import enTimezones from './enTimezones';
import enTools from './enTools';

export default {
  en: {
    translation: {
      hello: 'hello',
      Integrations: 'Integrations',
      Link: 'Link',
      Unlink: 'Unlink',
      'Use Chrome': 'Use Chrome',
      DownloadChrome_Text: 'We have detected that you are not using the Chrome browser. To ensure the best Uniboxi experience, we recommend switching to Chrome or downloading it if you don\'t have it.',
      'Download Chrome': 'Download Chrome',
      'Add tag': 'Add tag',
      'Empty name error': 'The name cannot be empty',
      'New tag name': 'New tag name',
      'Add new conversation': 'Add new conversation',
      'Add contact': 'Add contact',
      'Send Whatsapp': 'Send Whatsapp',
      'New whatsapp message': 'New whatsapp message',
      'New whatsapp message help':
        'Write the WhatsApp number with the country code. It is not necessary to add "+" to the number.',
      'Edit category': 'Edit category name',
      New: 'New',
      Board: 'Board',
      List: 'List',
      Filter: 'Filter',
      Edit: 'Edit',
      Notes: 'Notes',
      'Add Note': 'Add note',
      Send: 'Send',
      'Type a message...': 'Type a message...',
      'Active sequence...': 'Active sequence...',
      Inbox: 'Inbox',
      Inbox_help: 'Drag the conversation to the category you wish',
      'No conversations': 'Drag conversations to this category',
      'No conversations inbox': 'No have new conversations',
      'Add Category': 'Add Category',
      'Assign category': 'Assign category',
      'Category Name': 'Category Name',
      'Delete category': 'Delete category',
      'Edit tag': 'Edit tag',
      'Choose a color': 'Choose a color',
      'Deleted conversations': 'Deleted conversations',
      'New name': 'New name',
      Confirm: 'Confirm',
      Accept: 'Accept',
      Cancel: 'Cancel',
      'Delete tag': 'Delete tag',
      Delete_tag_help1: 'Are you sure you want to delete this tag?',
      Delete_category_help1: 'Are you sure you want to delete this category?',
      Delete_category_help2:
        "Categories with clients can't be deleted. Please delete or move all the conversation to another category before deleted",
      Delete_help1: 'Are you sure you want to delete this conversation?',
      Delete_help2: 'You can find it in the deleted conversations section.',
      Restore_help1: 'Are you sure you want to restore this conversation?',
      Restore_help2: 'You can find it in the inbox section.',
      Remove_help1: 'Are you sure you want to permanently remove this conversation?',
      Remove_help2: 'You can find it in the deleted conversations section.',
      'Assign users': 'Assign users',
      'Assign rules': 'Assign rules',
      'Write your note': 'Write your note.',
      Delete: 'Delete',
      'Add field': 'Add field',
      'Insert file': 'Insert file',
      'Insert variable': 'Insert variable',
      'Go to templates': 'Go to templates',
      Actives: 'Active',
      Inactives: 'Inactive',
      'Activate user': 'Activate user',
      'Activate user help': 'Are you sure you want to activate this user?',
      'Deactivate user': 'Deactivate user',
      'Cancel invitation': 'Cancel invitation',
      'Deactivate user help': 'Are you sure you want to deactivate this user?',
      'Cancel invitation help': 'Are you sure you want to cancel this invitation?',
      'Edit user': 'Edit user',
      'Edit users': 'Edit users',
      'Delete user': 'Delete user',
      'Delete user help': 'Are you sure you want to delete this user?',
      'Invitation pending': 'Invitation pending',
      After: 'After',
      'without answer': 'without answer',
      'We are sorry': 'We are sorry!',
      'No available chat': 'Sending messages from this channel kind will be available soon.',
      Oops: 'Oops!',
      'We cant find the page you were looking for': 'We cant find the page you were looking for.',
      'You can go to the': 'You can go to the ',
      'last page': 'last page',
      'or direct to': 'or direct to ',
      'main dashboard': 'main dashboard.',
      'home screen': 'home screen ',
      'or contact our': 'or contact our ',
      'help center': 'help center ',
      'so we can solve your problem': 'so we can solve your problem.',
      Hours: 'Hour(s)',
      Days: 'Day(s)',
      Weeks: 'Week(s)',
      'Restarts in': 'Restarts in',
      days: 'days',
      Language: 'Language',
      unknown_first_contact: 'Unknown',
      you: 'You',
      'Mark as read': 'Mark as read',
      'Mark as favourite': 'Mark as favourite',
      'Unmark as favourite': 'Unmark as favourite',
      'Mark as unread': 'Mark as unread',
      rate_limit_exceded: 'Rate limit exceeded for Channel. Please try again later',
      timeout_sending: 'It took too long to receive the message, please check if the number is correct.',
      invalid_number: 'Message not delivered. This WhatsApp number doesn’t exist.',
      invalid_number_help:
        'Please check if the number is correct. If it is incorrect, please delete the card and create a new one for this contact.',
      'Know more': 'Know more.',
      'More information': 'More information',
      'Send invitation': 'Send invitation',
      Delete_sequence_help: 'Are you sure you want to delete this sequence?',
      Delete_template_help: 'Are you sure you want to delete this template?',
      'No users help': 'Add or delete users from Uniboxi.',
      'Search templates': 'Search templates',
      'Search sequences': 'Search sequences',
      See: 'See',
      All: 'All',
      Everything: 'Everything',
      Everybody: 'Everybody',
      'Created by me': 'Created by me',
      Contact_row_moved: 'It will be relocated in chronological order.',
      Conversations: 'conversations',
      Active: 'Active',
      Inactive: 'Inactive',
      'Drag the categories to the position you want':
        'Drag the categories to the position you want.',
      'Change order of categories': 'Change order of categories',
      Information24h:
        'When creating a sequence, you have a 24 hour window to send all the messages. After 24 hours of the customer’s last message, you can not communicate with them until they write you back again (In accordance with Facebook Messenger & Whatsapp Policy).',
      'Unlink channel': 'Unlink channel',
      'Your channel': 'Your channel',
      'has disengaged. Click on the': 'has disengaged. Click on the',
      'next link': 'next link',
      'to re-link': 'to re-link.',
      'Authentication failed. User not found.': 'Authentication failed. User not found.',
      'Authentication failed. Wrong password.': 'Authentication failed. Wrong password.',
      'The company isnt confirmed yet.': "The company isn't confirmed yet.",
      Search: 'Search',
      'Export in .csv format': 'Export in .csv format',
      'Export in .xlsx format': 'Export in .xlsx format',
      'Save contact': 'Save contact',
      'Export dashboard': 'Export dashboard',
      'Select one of the following options to download your dashboard':
        'Select one of the following options to download your dashboard.',
      'Whole board': 'Whole board',
      'Time frame': 'Time frame',
      export_help:
        'We will send an email to {{email}} with the link to download the {{type}} file.',
      'Export board': 'Export board',
      Start: 'Start',
      End: 'End',
      Previous: 'Previous',
      Next: 'Next',
      'Send to': 'Send to',
      'Message for': 'Message for:',
      'Select the pre-approved template you want to send':
        'Select the pre-approved template you want to send:',
      'Sending from': 'Sending from',
      'Add Information (Optional)': 'Add Information (Optional)',
      NewWhatsappMessageHelp:
        'WhatsApp only allows you to use pre-approved templates to contact a person for the first time.',
      NewWhatsappNoTemplates:
        'WhatsApp only allows you to use pre-approved templates to contact a person for the first time. To add your first payment template, click below.',
      NewWhatappPlanUpgrade:
        'Exclusive Pro Plan functionality. Click below to upgrade your account.',
      'Go to planes': 'Ir a planes',
      'Complete the following fields with the information you want':
        'Complete the following fields with the information you want.',
      'View inactive': 'View inactive',
      NoIntegratedChannelsTitle: 'Have better conversations with your customers using messaging.',
      NoIntegratedChannelsList1: 'A simple dashboard to see all your WhatsApp, Instagram and Facebook Messenger messages in one place.',
      NoIntegratedChannelsList2: 'Add your entire sales or support team and split incoming messages between them. Then easily monitor your team\'s conversations.',
      NoIntegratedChannelsList3: 'Start by integrating a WhatsApp number, Instagram or Messenger account.',
      'Start by integrating a WhatsApp number, Instagram or Messenger account.': 'Start by integrating a WhatsApp number, Instagram or Messenger account.',
      'Integrate a channel': 'Integrate a channel',
      'Transfer conversations in bulk': 'Transfer conversations in bulk',
      'Are you sure you want to transfer all': 'Are you sure you want to transfer all',
      conversations: 'conversations',
      from: 'from',
      to: 'to',
      'Go back': 'Go back',
      'Choose the user who currently owns the conversations.': 'Choose the user who currently owns the conversations.',
      'What categories of conversations do you want to transfer?': 'What categories of conversations do you want to transfer?',
      'Choose the user you\'ll transfer the conversations to.': 'Choose the user you\'ll transfer the conversations to.',
      Continue: 'Continue',
      'Transfer completed': 'Transfer completed',
      'The transfer was completed successfully.': 'The transfer was completed successfully.',
      'Active users': 'Active users',
      'Inactive users': 'Inactive users',
      'Select a user': 'Select a user',
      'Search in': 'Search in',
      'Drop your files here': 'Drop your files here',
      'Copy image': 'Copy image',
      'Download image': 'Download image',
      Status: 'Status',
      Categories: 'Categories',
      'Delete batch_send': 'Delete batch send',
      Delete_batch_send_help1: 'Are you sure you want to delete this batch send?',
      cant_deactivate_with_clients_assigned: 'It is not possible to deactivate users who have assigned conversations. Please transfer al their conversations to another user and try again.',
      'Transfer conversations': 'Transfer conversations',
      ...enIntegrations,
      ...enLogin,
      ...enTopbar,
      ...enDrawer,
      ...enAccount,
      ...enEllipsisPopover,
      ...enTemplates,
      ...enSequences,
      ...enRules,
      ...enChat,
      ...enFilter,
      ...enDashboard,
      ...enNotifications,
      ...enAutomation,
      ...enReports,
      ...enTimezones,
      ...enTools,
    },
  },
};
