export default {
  'Add to favorites': 'Add to favorites',
  'Delete from favorites': 'Delete from favorites',
  'Assign Category': 'Assign Category',
  'Mark as spam': 'Mark as spam',
  'Unite contacts': 'Unite contacts',
  'Assign tag': 'Assign tag',
  'Delete conversation': 'Delete conversation',
  'Restore conversation': 'Restore conversation',
  'Permanently remove': 'Permanently remove',
  'Switch assigned user': 'Switch assigned user',
  'Change assigned user': 'Change assigned user',
  'Choose below the user who will manage this conversation.': 'Choose below the user who will manage this conversation.',
};
