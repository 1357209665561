export default {
  'Payment required': 'Pago requerido',
  'Your trial period will end soon': 'Su período de prueba finalizará pronto',
  'We inform you that your free trial period ends in 5 days. To continue using Uniboxi without interruptions, we invite you to choose a plan':
    'Te informamos que su periodo de prueba gratuita finaliza en 5 dias. para continuar utilizando Uniboxi sin interrupciones te invitamos a elegit un plan.',
  'You are reaching the limit of WhatsApp messages':
    'La cantidad de mensajes de WhatsApp está llegando a su límite',
  'Wed like to let you know that the account is running low on available WhatsApp messages. The account admin must upgrade the plan':
    'Te informamos que la cantidad de mensajes de WhatsApp se está agotando. El administrador de la cuenta debe hacer el upgrade del plan.',
  'The account has reached the limit of WhatsApp messages':
    'La cuenta ha llegado al límite de mensajes de WhatsApp',
  'No whatsapp messages left description':
    'La cuenta seguirá recibiendo mensajes pero no se podrán responder hasta que comience el nuevo período o se haga el cambio de plan.',
  'Inactive account': 'Cuenta inactiva',
  'Please make a payment to continue using Uniboxi. To renew or sign up for a new subscription, please write to admin@uniboxi.com':
    'Por favor realiza un pago para continuar usando Uniboxi. Para renovar o contratar una nueva suscripción, por favor escribe a admin@uniboxi.com',
  'See plans': 'Ver planes',
  'Update sort clients title': 'Hemos actualizado el tablero principal',
  'Update sort clients description':
    'Desde ahora todas las conversaciones del tablero se ordenarán de forma automática según el último mensaje recibido o enviado. Con este cambio ya no se podrán ordenar las tarjetas manualmente dentro de la categoría ya que siempre estarán ordenadas según la fecha del último mensaje.',
  'The account has run out of WhatsApp messages and the account admin must buy more credits':
    'La cuenta se quedó sin mensajes de WhatsApp y el administrador de la cuenta debe comprar más créditos.',
  'Uniboxi keeps accepting new WhatsApp messages but you can not reply to them until the account has new credits':
    'Uniboxi sigue aceptando nuevos mensajes de WhatsApp pero no puedes responder a ellos hasta que la cuenta tenga nuevos créditos.',
};
