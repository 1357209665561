import React, { Component } from 'react';
import { withRouter } from 'react-router';

import Fallback from './components/common/Fallback';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    const { history } = this.props;
    this.state = {
      hasError: false,
    };

    history.listen(() => {
      const { hasError } = this.state;
      if (hasError) {
        this.setState({
          hasError: false,
        });
      }
    });
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { children } = this.props;
    const { hasError, error, errorInfo } = this.state;
    const { NODE_ENV: env } = process.env;

    if (hasError && env === 'production') {
      return <Fallback error={error} errorInfo={errorInfo} />;
    }
    return children;
  }
}
export default withRouter(ErrorBoundary);
