export default {
  Automation: 'Automatización',
  'Create message': 'Crear mensaje',
  Create: 'Crear',
  'No automation help':
    'Envíe mensajes de forma automática a sus clientes, incluso cuando su negocio se encuentre cerrado, asignando los horarios y días correspondientes.',
  'No automations': 'No hay mensajes automáticos.',
  'Automation help': 'Envíe mensajes de forma automática a sus clientes',
  'Automatic messages': 'Mensajes automáticos',
  'Automatic messages help':
    'Envíe mensajes de forma automática a sus clientes cuando su negocio se encuentre cerrado, asignando los horarios y días correspondientes.',
  Message: 'Mensaje',
  'Message help':
    'Escriba el mensaje que se mostrará a quienes escriban a sus cuentas en los horarios programados.',
  'You have not yet written a message': 'Aún no ha escrito un mensaje',
  'Edit message': 'Editar mensaje',
  'Save message': 'Guardar mensaje',
  Programming: 'Programación',
  'Prograaming help': 'Programe los horarios en los que enviará el mensaje.',
  'Schedule time': 'Programar horario',
  'Schedule message': 'Programar mensaje',
  'Schedule message help':
    '¿Desea que su mensaje se envíe únicamente en horarios específicos?',
  'Time zone': 'Zona horaria',
  'Next day': 'Próximo día',
  Since: 'Desde',
  From: 'Hasta',
  To: 'Hasta',
  Days: 'Días',
  'Add schedule': 'Agregar horario',
  'Active message': 'Mensaje activo',
  'Edit scheduled times': 'Editar horarios programados',
  Sunday: 'Domingo',
  Monday: 'Lunes',
  Tuesday: 'Martes',
  Wednesday: 'Miércoles',
  Thursday: 'Jueves',
  Friday: 'Viernes',
  Saturday: 'Sábado',
  day1: 'Lunes',
  day2: 'Martes',
  day3: 'Miércoles',
  day4: 'Jueves',
  day5: 'Viernes',
  day6: 'Sábado',
  day7: 'Domingo',
  letterSunday: 'D',
  letterMonday: 'L',
  letterTuesday: 'M',
  letterWednesday: 'M',
  letterThursday: 'J',
  letterFriday: 'V',
  letterSaturday: 'S',
  'Delete message': 'Eliminar mensaje',
  'Days and hours': 'Días y horarios',
  'Instant delivery': 'Envío instantáneo',
  'Add automatic message': 'Agregar mensaje automático',
  'Update automatic message': 'Modificar mensaje automático',
  'Schedule your automation': 'Programe su automatización',
  Optional: '(Opcional)',
  'Delete automated message': 'Eliminar mensaje automatizado',
  Delete_message_help: 'Esta acción no se puede volver atrás.',
  'Please write your message here': 'Por favor escribe tu mensaje acá.',
  InformationInstantDelivery:
    'Se enviará automáticamente 10 segundos después que entra una nueva conversación a Uniboxi. No se le mostrarán a los contactos que hayan tenido actividad en Uniboxi previamente.',
  InformationScheduleDays:
    'Se enviará cada vez que entre un mensaje a Uniboxi en el horario definido. Si alguien escribe múltiples veces durante el horario definido, no se le mostrará más de una vez cada 24 horas.',
  'Select the type of message you want to create': 'Selecciona el tipo de mensaje que deseas crear.',
  InstantMessageDescription: 'Estos mensajes se activan cuando un nuevo contacto inicia una conversación con tu empresa. Se utilizan principalmente para dar la bienvenida a nuevos clientes o para hacerles saber que estarás con ellos en minutos.',
  ProgrammedMessageDescription: 'Elige el día y la hora para activar un mensaje en particular a todos los mensajes entrantes que recibe tu empresa. Estos son ideales para mensajes fuera del horario de oficina o para los fines de semana.',
  'Message type: ': 'Tipo de mensaje: ',
  'Programmed response': 'Respuesta programada',
  'Instant response': 'Respuesta instantánea',
  'Messages for weekends or out of office.': 'Mensajes para los fines de semana o fuera de oficina.',
  'Welcome, initial greeting, first autoresponder, etc.': 'Bienvenida, Saludo inicial, primera respuesta automática, etc.',
  'Welcome messages for new conversations.': 'Mensajes de bienvenida para nuevas conversaciones.',
  'Create autoreply': 'Crear automatización',
  'Update autoreply': 'Modificar automatización',
  'Deactivate message': 'Desactivar mensaje',
  'Activate message': 'Activar mensaje',
};
