export default {
  'Pacific/Wallis': 'Línea internacional de cambio de fecha',
  'Pacific/Midway': 'islas Midway, Samoa',
  'US/Hawaii': 'Hawaii',
  'US/Alaska': 'Alaska',
  'US/Pacific': 'Tiempo del Pacífico (EEUU y Canadá); Tijuana',
  'US/Mountain': 'Tiempo de Montaña (EEUU y Canadá)',
  'America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
  'US/Arizona': 'Arizona',
  'US/Central': 'Hora estándar del centro',
  'Canada/Saskatchewan': 'Saskatchewan',
  'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
  'America/Managua': 'America Central',
  'US/Eastern': 'Horario del este de América del Norte (EEUU y Canada)',
  'US/East-Indiana': 'Indiana (Este)',
  'America/Lima': 'Bogota, Lima, Quito',
  'Canada/Atlantic': 'Tiempo del Atlántico',
  'America/Caracas': 'Caracas, La Paz',
  'America/Santiago': 'Santiago',
  'Canada/Newfoundland': 'Terranova y Labrador',
  'America/Sao_Paulo': 'Brasilia',
  'America/Buenos_Aires': 'Buenos Aires, Georgetown',
  'America/Godthab': 'Groenlandia',
  'Atlantic/Azores': 'Azores',
  'Atlantic/Cape_Verde': 'Cabo Verde',
  'Europe/London': 'Hora media de Greenwich: Dublin, Edinburgh, Lisbon, London',
  'Africa/Casablanca': 'Casablanca, Monrovia',
  'Europe/Budapest': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Warsaw': 'Sarajevo, Skopje, Varsovia, Zagreb',
  'Europe/Paris': 'Bruselas, Copenhague, Madrid, París',
  'Europe/Amsterdam': 'Amsterdam, Berlin, Berna, Roma, Estocolmo, Viena',
  'Africa/Lagos': 'Hora estándar de África occidental',
  'Europe/Athens': 'Atenas, Bucarest',
  'Africa/Cairo': 'Cairo',
  'Europe/Helsinki': 'Helsinki, Kiev, Riga, Sofía, Tallin, Vilna',
  'Europe/Istanbul': 'Estambul',
  'Europe/Minsk': 'Minsk',
  'Asia/Jerusalem': 'Jerusalén',
  'Africa/Harare': 'Harare, Pretoria',
  'Europe/Moscow': 'Moscú, San Petersburgo, Volgogrado',
  'Asia/Kuwait': 'Kuwait, Riad',
  'Africa/Nairobi': 'Nairobi',
  'Asia/Baghdad': 'Bagdad',
  'Asia/Tehran': 'Teherán',
  'Asia/Muscat': 'Abu Dabi, Mascate',
  'Asia/Baku': 'Bakú',
  'Asia/Tbilisi': 'Tiflis',
  'Asia/Yerevan': 'Ereván',
  'Asia/Kabul': 'Kabul',
  'Asia/Yekaterinburg': 'Ekaterimburgo',
  'Asia/Karachi': 'Islamabad, Karachi',
  'Asia/Tashkent': 'Taskent',
  'Asia/Kolkata': 'Chennai, Calcuta, Bombay, Nueva Delhi',
  'Asia/Katmandu': 'Katmandú',
  'Asia/Dhaka': 'Astaná, Daca',
  'Asia/Calcutta': 'Sri Jayawardenapura Kotte',
  'Asia/Novosibirsk': 'Almatý, Novosibirsk',
  'Asia/Rangoon': 'Rangún',
  'Asia/Bangkok': 'Bangkok, Hanói, Yakarta',
  'Asia/Krasnoyarsk': 'Krasnoyarsk',
  'Asia/Hong_Kong': 'Pekín, Chongqing, Hong Kong, Urumchi',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapur',
  'Asia/Taipei': 'Taipéi',
  'Australia/Perth': 'Perth',
  'Asia/Ulaanbaatar': 'Ulán Bator',
  'Asia/Irkutsk': 'Irkutsk',
  'Asia/Seoul': 'Seúl',
  'Asia/Tokyo': 'Osaka, Sapporo, Tokio',
  'Asia/Yakutsk': 'Yakutsk',
  'Australia/Darwin': 'Darwin',
  'Australia/Adelaide': 'Adelaida',
  'Australia/Melbourne': 'Canberra, Melbourne, Sídney',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Hobart': 'Hobart',
  'Asia/Vladivostok': 'Vladivostok',
  'Pacific/Port_Moresby': 'Guam, Puerto Moresby',
  'Pacific/Guadalcanal': 'Islas Salomón, Nueva Caledonia',
  'Asia/Magadan': 'Magadán',
  'Pacific/Fiji': 'Fiyi',
  'Asia/Kamchatka': 'Península de Kamchatka',
  'Pacific/Auckland': 'Auckland, Wellington',
  'Pacific/Tongatapu': 'Nukualofa',
};
