export default {
  Email: 'Email',
  Password: 'Password',
  'Show password': 'Show password',
  'Hide password': 'Hide password',
  'Confirm password': 'Confirm password',
  Login: 'Sign in',
  'Forgot your password': 'Forgot your password?',
  'Dont have an account?': 'Dont have an account?',
  Register: 'Register',
  'Send email': 'Send email',
  'New password': 'New password',
  'Repeat new password': 'Repeat new password',
  'Change password': 'Change password',
  'Restore password': 'Restore Password',
  'Expired password': 'Expired Password',
  'Disabled user': 'Disabled user',
  'Unconfirmed company':
    'This account must be verified before requesting a new password. We just sent you the activation email again.',
  forgotHelp:
    'Enter the email for your account. We will send you intructions to retrieve your password.',
  'No user for that email': 'This email address is not associated with any account in Uniboxi.',
  sentForgotHelp: 'We sent you an email to recover your password, please check your inbox',
  resetHelp: 'Please enter your new password',
  'This link has expired. Please request a new password again from':
    'This link has expired. Please request a new password again from',
  here: 'here',
  refreshHelp:
    'Your password has expired, please enter your current password and your new password',
  Back: 'Go back',
  resultACCEPTED: 'Your email was sent successfully.',
  error: 'Error, please try again later.',
  errorDISABLED: 'Your credentials have been disabled.',
  errorREJECTED: 'Wrong email or password',
  errorUNACCEPTABLE_INTERACTION: 'Please try again.',
  errorUNACCEPTABLE_PASSWORD: 'Presented credentials do not fulfill security requirements.',
  errorBLOCKED: 'Your credentials have been blocked.',
  errorINTERNAL_ERROR: 'Internal Error, please try again later.',
  errorPASSWORD_DONT_MATCH: "Passwords don't match",
  errorCOMPLETE_PASSWORDS: 'Please complete passwords.',
  errorCOMPLETE_FIELDS: 'Please complete fields.',
  'Passwords dont match': 'The passwords don’t match.',
  'Password too short': 'The password is too short. Minimum 6 characters long.',
  'Password must contain a letter and a number': 'The password must contain a letter and a number.',
  errorEXPIRED_TOKEN: 'Your security token has expired, please request a new one.',
  resetPasswordMessage: 'Your password was changed successfully. Please try log in now.',
  changedPasswordMessage: 'Your password was changed successfully. Please try log in now.',
  'Password changed successfully': 'Password changed successfully.',
  'Welcome to Uniboxi': 'Welcome to Uniboxi',
  'Start free trial': 'Start your 7 days of free trial',
  'No credit card required': 'No credit card required',
  'Already have an account?': 'Already have an account?',
  'It looks like you already have an account!': 'It looks like you already have an account!',
  Name: 'Name',
  Lastname: 'Lastname',
  Company: 'Company',
  'Repeat password': 'Repeat password',
  'Start trial': 'Start trial',
  'Sign up': 'Sign up',
  'Check your mail': 'Check your mail',
  'We sent an email to': 'We have sent an email to',
  'Confirm your mail':
    'to verify your email address. Once you receive it, please open the link provided inside to complete the validation process.',
  'in x seconds': 'in {{timer}} seconds',
  'email confirmation fowarded': 'Email confirmation fowarded',
  'If you already activated your account, please login here':
    'If you already activated your account, please login here',
  "We couldn't find a user for this token": "We couldn't find a user for this token",
  'You didnt received any email?': 'You didnt received any email?',
  'Resend the confirmation email': 'Resend the confirmation email',
  'Action can be repeated': 'This action can be repeated',
  'Thank you for verifying your account!': 'Thank you for verifying your account!',
  'We will guide you step by step to configure your account correctly':
    'We will guide you step by step to configure your account correctly.',
  Continue: 'Continue',
  'Email unavailable': 'Email unavailable',
  'Email invalid': 'Email is invalid or missing.',
  'First name required': 'First name is required.',
  'First name invalid length': 'First name have an invalid length',
  'Last name required': 'Last name is required.',
  'Last name invalid length': 'Last name have an invalid length',
  'Company name required': 'Company field is required.',
  'Company name invalid length': 'Company name have an invalid length',
  'Create password': 'Create password',
  createPasswordHelp: 'Please enter a password.',
  Testimony:
    'We hired Uniboxi because we needed to use Whatsapp for our customer support team of 5 people. We can split all the incoming messages among the support team and classify each conversation in different categories. We are now also adding another channel (Messenger) to let our customers contact us directly from Facebook as well.',
};
