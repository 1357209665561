export default {
  default: 'Regla por defecto',
  'Create assignment rule': 'Crear regla de asignación',
  'Round-Robin': 'Forma equitativa',
  'Custom assignment': 'Forma personalizada',
  Low: 'Baja',
  Medium: 'Media',
  High: 'Alta',
  'Create rule': 'Crear regla',
  'Assigned total': 'Total asignado',
  'Edit assignment rule': 'Editar regla de asignación',
  'Delete assignment rule': 'Eliminar regla de asignación',
  'Save assignment rule': 'Guardar regla de asignación',
  "Default rule can't be edited": 'La regla default no puede ser modificada',
  'Please complete the name of the rule': 'Por favor complete el nombre de la regla',
  'Please complete the channels of the rule': 'Por favor complete los canales de la regla',
  'Please complete the users of the rule': 'Por favor complete los usuarios de la regla',
  'The percentages dont add 100': 'Porcentaje total asignado debe ser 100%',
  'Please assign channels': 'Por favor asigne los canales',
  'Please assign users': 'Por favor asigne los usuarios',
  'No rules help': 'Crea reglas para asignar los mensajes entrantes con tus usuarios.',
  'All the incoming messages will be assigned evenly among the users':
    'Todos los mensajes entrantes serán asignadas de forma equitativa entre los usuarios.',
  'Create a custom rule to assign some users more messages than others': 'Crea una regla para asignarle más mensajes a algunos usuarios.',
};
