/* eslint-disable no-constant-condition */
import React from 'react';
import '@dotlottie/player-component';
import loading from '../../images/lottie/loading.lottie';

import Images from '../../themes/Images';

// eslint-disable-next-line react/prefer-stateless-function
class SuspenseFallback extends React.Component {
  render() {
    return (
      <div
        style={{
          minHeight: '100vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ width: 125 }}>
          {true ? (
            <img src={Images.grayLogo} alt="Logo" />
          ) : (
            <dotlottie-player
              src={loading}
              autoplay
              loop
            />
          )}
        </div>
      </div>
    );
  }
}

export default SuspenseFallback;
