export default {
  Email: 'Email',
  Password: 'Contraseña',
  'Show password': 'Mostrar contraseña',
  'Hide password': 'Ocultar contraseña',
  'Confirm password': 'Confirmar contraseña',
  Login: 'Iniciar Sesión',
  'Forgot your password': '¿Olvidaste tu contraseña?',
  'Dont have an account?': '¿No tiene cuenta en uniboxi?',
  Register: 'Registrarse',
  'Send email': 'Enviar Email',
  'New password': 'Nueva contraseña',
  'Repeat new password': 'Repetir nueva contraseña',
  'Change password': 'Cambiar contraseña',
  'Restore password': 'Recuperar contraseña',
  'Expired password': 'Su contraseña ha expirado',
  'Disabled user': 'Usuario deshabilitado',
  'Unconfirmed company':
    'Esta cuenta debe ser verificada antes de pedir contraseña. Recién te enviamos nuevamente el correo de activación.',
  forgotHelp: 'Este correo electrónico no está asociado con ninguna cuenta en Uniboxi.',
  'No user for that email':
    'Para recuperar su contraseña por favor ingrese su correo electrónico. Le enviaremos un correo con las indicaciones para poder completar el proceso.',
  sentForgotHelp: 'Le enviamos un link para recuperar su contraseña, por favor revise su inbox.',
  resetHelp: 'Ingrese a continuacion su nueva contraseña',
  'This link has expired. Please request a new password again from':
    'Este enlace expiró. Por favor solicita una nueva clave desde',
  here: 'aquí',
  refreshHelp:
    'Su contraseña ha expirado, por favor ingrese su contraseña actual y a continuacion su nueva contraseña.',
  Back: 'Volver',
  resultACCEPTED: 'Email enviado correctamente.',
  error: 'Hubo un error, por favor reintente',
  errorDISABLED: 'Sus credenciales han sido desahabilitadas.',
  errorREJECTED: 'Email o contraseña incorrectos.',
  errorUNACCEPTABLE_INTERACTION: 'Por favor intente nuevamente',
  errorUNACCEPTABLE_PASSWORD: 'La contraseña no cumple con los requerimientos de seguridad',
  errorBLOCKED: 'Sus credenciales han sido bloqueadas.',
  errorINTERNAL_ERROR: 'Error interno, Por favor intente nuevamente.',
  errorPASSWORD_DONT_MATCH: 'Las claves no coinciden.',
  errorCOMPLETE_PASSWORDS: 'Por favor complete las claves.',
  errorCOMPLETE_FIELDS: 'Por favor complete los campos.',
  errorEXPIRED_TOKEN: 'Tu token de seguridad ha expirado, por favor solicite un nuevo mail.',
  'Incorrect current password': 'Contraseña incorreta.',
  "Passwords don't match": 'Las claves no coinciden.',
  'Password changed succesfully': 'Contraseña cambiada con éxito.',
  'Passwords dont match': 'Las contraseñas no son iguales, revisar por favor.',
  'Password too short': 'La contraseña es muy corta. Mínimo 6 caracteres.',
  'Password must contain a letter and a number':
    'La contraseña debe contener una letra y un número.',
  resetPasswordMessage:
    'Su contraseña fue cambiada correctamente. Por favor intente ingrese nuevamente.',
  changedPasswordMessage:
    'Su contraseña fue cambiada correctamente. Por favor intente ingrese nuevamente.',
  'Welcome to Uniboxi': 'Bienvenido a Uniboxi',
  'Start free trial': 'Empiece sus 7 días de prueba.',
  'No credit card required': 'Sin tarjeta de crédito requerida.',
  'Already have an account?': '¿Ya tiene una cuenta?',
  'It looks like you already have an account!': 'Parace que ya tienes una cuenta!',
  Name: 'Nombre',
  Lastname: 'Apellido',
  Company: 'Compañia',
  'Repeat password': 'Repetir contraseña',
  'Start trial': 'Comenzar prueba',
  'Sign up': 'Registrarse',
  'Check your mail': 'Revise su correo',
  'We sent an email to': 'Hemos enviado un correo electrónico a',
  'Confirm your mail':
    'para confirmar la validez de su dirección de correo. Una vez que lo reciba, por favor, abra el enlace que se encuentra en el correo para finalizar el proceso de validación.',
  'in x seconds': 'en {{timer}} segundos',
  'email confirmation fowarded': 'Email de confirmación reenviado',
  'If you already activated your account, please login here':
    'Si ya activó la cuenta, inicie sesión desde aquí',
  "We couldn't find a user for this token": 'No encontramos un usuario para este token',
  'You didnt received any email?': '¿No recibio ningun email?',
  'Resend the confirmation email': 'Reenviar correo de confirmación',
  'Action can be repeated': 'Esta acción se puede repetir',
  'Thank you for verifying your account!': 'Gracias por verificar su cuenta!',
  'We will guide you step by step to configure your account correctly':
    'A continuacion lo guiaremos paso a paso para configurarla correctamente.',
  Continue: 'Continuar',
  'Email unavailable': 'El email no esta disponible',
  'Email invalid': 'Email es inválido o no existe.',
  'First name required': 'El campo nombre es obligatorio',
  'First name invalid length': 'El campo nombre tiene un largo inválido',
  'Last name required': 'El campo apellido es obligatorio.',
  'Last name invalid length': 'El campo apellido tiene un largo inválido',
  'Company name required': 'El campo compañia es obligatorio.',
  'Company name invalid length': 'El campo compañia tiene un largo inválido',
  'Create password': 'Crear contraseña',
  createPasswordHelp: 'Por favor ingrese una contraseña.',
  Testimony:
    'Contratamos Uniboxi porque necesitábamos usar Whatsapp para el equipo de soporte de 5 personas que tenemos. Ahora podemos dividir los mensajes entrantes entre todo el equipo y clasificar las conversaciones en categorías. Ahora vamos a integrar otro canal (Messenger) para permitirle a nuestros clientes que nos contacten desde Facebook también. ',
};
