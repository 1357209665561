export default {
  'Add to favorites': 'Agregar a favoritos',
  'Delete from favorites': 'Eliminar de favoritos',
  'Assign Category': 'Asignar a categoría',
  'Mark as spam': 'Marcar como spam',
  'Unite contacts': 'Unir contactos',
  'Assign tag': 'Asignar etiqueta',
  'Delete conversation': 'Eliminar conversación',
  'Restore conversation': 'Restaurar conversación',
  'Permanently remove': 'Eliminar definitivamente',
  'Switch assigned user': 'Cambiar usuario asignado',
  'Change assigned user': 'Cambiar usuario asignado',
  'Choose below the user who will manage this conversation.': 'Seleccionar el usuario que administrará la conversación',
};
