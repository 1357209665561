export default {
  Companies: 'Compañias',
  'Massive sends': 'Envios masivos',
  'Select company': 'Seleccione compañia',
  'Add spreadsheet': 'Añadir hoja de cálculo',
  'Add batch send': 'Añadir envio masivo',
  'Add new spreadsheet': 'Añadir nueva hoja de cálculo',
  'Add new batch send': 'Añadir nuevo envio masivo',
  'Only .csv spreadsheets are allowed': 'Solo hojas del tipo .csv estan permitidas',
  'Are you sure you want to delete the spreadsheet?': '¿Estas seguro que eliminar la hojas de cálculo?',
  'Company ID': 'ID Compañia',
  Owner: 'Propietario',
  'Owner email': 'E-mail propietario',
  'Payment method': 'Forma de pago',
  Status: 'Estado',
  Users: 'Usuarios',
  'Available users': 'Usuarios disponibles',
  'Signup date': 'Fecha de alta',
  Integrations: 'Integraciones',
  'Ammount of Messenger messages': 'Cant. Mensajes Messenger',
  'Ammount of Whatsapp messages': 'Cant. Mensajes Whatsapp',
  Actions: 'Acciones',
  See: 'Ver',
  manual: 'Manual',
  stripe: 'Stripe',
  inactive: 'Inactiva',
  active: 'Activa',
  trial: 'Trial',
  Processing: 'Procesando',
  Accepted: 'Aceptado',
  Rejected: 'Rechazado',
  Deleted: 'Eliminado',
  Finished: 'Finalizado',
  file: 'archivo',
  'No number': 'Sin número',
  'Pre-approved templates': 'Plantillas preaprobadas',
  'Create pre-approved template': 'Crear plantillas preaprobada',
  'Begin of period': 'Inicio del período',
  'Ammount of WhatsApp pre-approved templates': 'Cant. plantillas preaprobadas WhatsApp',
  'WhatsApp messages cost': 'Costo mensajes Whatsapp',
  'WhatsApp pre-approved templates cost': 'Costo plantillas preaprobadas Whatsapp',
  'Total WhatsApp cost': 'Costo total WhatsApp',
  'Twilio SID number': 'Número Twilio SID',
  'Channel type integrated': 'Tipos de canal integrados',
  'Transfer conversations': 'Transferir conversaciones',
};
