import moment from 'moment';

export const variablesInitialState = {
  trash: false,
  timezone: moment().format('Z'),
  filters:
  {
    filter: '',
    dateFilter: 'all',
    onlyStarred: false,
    onlyUnread: false,
    tagsFilter: [],
    channelsFilter: [],
    usersFilter: [],
  },
};

export const VARIABLES_ACTION_TYPES = {
  SET_ONLY_STARRED: 'SET_ONLY_STARRED',
  SET_ONLY_UNREAD: 'SET_ONLY_UNREAD',
  SET_FILTER: 'SET_FILTER',
  SET_DATE_FILTER: 'SET_DATE_FILTER',
  SET_TAGS_FILTER: 'SET_TAGS_FILTER',
  SET_CHANNEL_FILTER: 'SET_CHANNEL_FILTER',
  SET_USERS_FILTER: 'SET_USERS_FILTER',
  SET_TRASH: 'SET_TRASH',
  CLEAR_FILTERS: 'CLEAR_FILTERS',
};

const UPDATE_STATE_BY_ACTION = {
  [VARIABLES_ACTION_TYPES.SET_ONLY_STARRED]:
   (state, action) => ({ ...state, filters: { ...state.filters, onlyStarred: action.payload } }),
  [VARIABLES_ACTION_TYPES.SET_ONLY_UNREAD]:
   (state, action) => ({ ...state, filters: { ...state.filters, onlyUnread: action.payload } }),
  [VARIABLES_ACTION_TYPES.SET_FILTER]:
   (state, action) => ({ ...state, filters: { ...state.filters, filter: action.payload } }),
  [VARIABLES_ACTION_TYPES.SET_DATE_FILTER]:
   (state, action) => ({ ...state, filters: { ...state.filters, dateFilter: action.payload } }),
  [VARIABLES_ACTION_TYPES.SET_TAGS_FILTER]:
   (state, action) => ({ ...state, filters: { ...state.filters, tagsFilter: action.payload } }),
  [VARIABLES_ACTION_TYPES.SET_CHANNEL_FILTER]:
   (state, action) => ({ ...state, filters: { ...state.filters, channelsFilter: action.payload } }),
  [VARIABLES_ACTION_TYPES.SET_USERS_FILTER]:
   (state, action) => ({ ...state, filters: { ...state.filters, usersFilter: action.payload } }),
  [VARIABLES_ACTION_TYPES.SET_TRASH]:
   (state, action) => ({ ...state, trash: action.payload }),
  [VARIABLES_ACTION_TYPES.CLEAR_FILTERS]:
   state => ({ ...state, filters: { ...variablesInitialState.filters } }),
};

export const variablesReducer = (state, action) => {
  const { type: actionType } = action;
  const updateState = UPDATE_STATE_BY_ACTION[actionType];
  return updateState ? updateState(state, action) : state;
};
