const Colors = {
  // Grays
  coal: '#3B3B3B',
  buttonGray: '#727272',
  mainGray: '#9E9E9E',
  lightGray: '#D9D9D9', // use to indicate disabled
  bgGray: '#F3F3F3',
  // System
  primary: '#0382E2',
  secondary: '#0DCBE9',
  darkPrimary: '#0066BD',
  chatCyan: '#C2E6FD',
  violet: '#AF03E2',
  red: '#E20303',
  yellow: '#FFA200',
  green: '#25D366',
  instagram: '#d10868',
  messenger: '#0A7CFF',
  whatsapp: '#33b928',
  // Custom
  snow: '#FFF',
  transparent: '#00000000',
  facebook: '#2743e0',
};

export default Colors;
