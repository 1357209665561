export default {
  'General adjustments': 'General adjustments',
  'General information': 'General information',
  Plan: 'Plan',
  'Plan / Integrations': 'Plan / Integrations',
  Payment: 'Payment',
  Notifications: 'Notifications',
  'Add photo': 'Add photo',
  'Change photo': 'Change photo',
  'Change email': 'Change email',
  'Current email': 'Current email',
  'New email': 'New email',
  'Change password': 'Change password',
  'Current password': 'Current password',
  'New password': 'New password',
  'Repeat new password': 'Repeat new password',
  'Change language': 'Change language',
  'The changes have been saved': 'The changes have been saved',
  'Create User': 'Add user',
  'First name': 'First name',
  'Last name': 'Last name',
  Email: 'Email',
  Phone: 'Phone',
  'Phone number': 'Phone number',
  Role: 'Rol',
  'New user': 'New user',
  Save: 'Save',
  User: 'User',
  Manager: 'Manager',
  Admin: 'Admin',
  'Admin abilities': 'Access to all administrative and account management functionality.',
  'Main admin': 'Main Admin',
  'Main admin abilities': 'Administrator with the ability to delete and modify administrators, granted by Uniboxi.',
  'Manager abilities':
    'All admin permissions, except add users, upgrade and downgrade of plans or export.',
  'Standard abilities': 'Access to all communication features within Uniboxi..',
  'Company information': 'Company information',
  'Enter this link, and copy the key with name': 'Enter this link, and copy the key with name',
  Contact: 'Contact',
  Security: 'Security',
  'Date and Language': 'Date and Language',
  'Date format': 'Date format',
  'Personal information': 'Personal information',
  'Member since': 'Member since',
  'Password changed': 'Password changed succesfuly',
  'Invalid password': 'Error while changing the password',
  'DD/MM/YYYY': 'DD/MM/YYYY',
  'MM/DD/YYYY': 'MM/DD/YYYY',
  'Current plan': 'Current plan',
  'Loading user': 'Loading user',
  'Integrated channels': 'Integrated channels',
  'Free trial': 'Free trial',
  standard: 'Standard',
  pro: 'Pro',
  'days left': 'days left',
  'WhatsApp messages': 'WhatsApp messages',
  'If you want to continue using Uniboxi after this period, you can choose the plan that best suits your needs':
    'If you want to continue using Uniboxi after this period, you can choose the plan that best suits your needs:',
  'I want to be notified by mail every time I receive a new message':
    'I want to be notified by mail every time I receive a new message.',
  'Monthly payment': 'Monthly payment',
  'Annual payment': 'Annual payment',
  'Per user, per month': 'Per user, per month',
  'Channels to integrate': 'Channels to integrate',
  Unlimited: 'unlimited',
  'Multichannel templates': 'Multichannel templates',
  'Automated sequences': 'Automated sequences',
  'Priority support': 'Priority support',
  'I want this plan': 'I want this plan',
  'Messages left': 'messages left',
  'Messages used': 'Messages used',
  'Payment information': 'Payment information',
  'Billing information': 'Billing information',
  Bills: 'Bills',
  'Add card': 'Add card',
  Update: 'Update',
  'Payment made': 'Payment made',
  'See detail': 'See detail',
  'Edit card': 'Edit card',
  Number: 'Number',
  'Security code': 'Security code',
  'Expiration date': 'Expiration date',
  'Use this card as default': 'Use this card as default',
  'Standard plan': 'Standard plan',
  'Pro plan': 'Pro plan',
  Address: 'Address',
  City: 'City',
  'Zip code': 'Zip code',
  Country: 'Country',
  'Whatsapp messages per user': 'Whatsapp messages per user',
  'email in use': 'Email already on use. Please use another one.',
  'email in use in other company':
    'This email is already associated with another Uniboxi account. Please use another email.',
  'email in use in the company':
    'This email is already associated with a company user. Please go to the Users page and search for it. In case it is inactive, you can reactivate it right there.',
  'invalid email': 'Invalid email format. Please check it.',
  'Field name': 'Name',
  'Field type': 'Field type',
  'Text field': 'Text field',
  'text field': 'Text field',
  'Number field': 'Number field',
  'number field': 'Number field',
  'Date field': 'Date field',
  'date field': 'Date field',
  'Select field': 'Select field',
  'select field': 'Select field',
  'Multiline text field': 'Multiline text field',
  'multiline_text field': 'Multiline text field',
  'Fixed field': 'Select field',
  'fixed field': 'Select field',
  'There are no inactive properties at this time': 'There are no inactive properties at this time.',
  Options: 'Options',
  'Add option': 'Add option',
  'Option name': 'Option name',
  'Uniboxi balance': 'Uniboxi balance',
  'Add funds': 'Add funds',
  InformationUniboxiFunds:
    'Uniboxi funds allow you to send pre-approved templates in WhatsApp (Pro plan) and also send standard WhatsApp messages once your account has reached the monthly limit.',
  InformationPaidMessages:
    'Whatsapp charges a low fee for each time a paid template is used. The amount displayed here shows the current available fund which is used among all the users within the account.',
  'Ends in': 'Ends in',
  'End of period': 'End of period',
  'Pre-approved WhatsApp messages': 'Pre-approved WhatsApp messages',
  'Contract plan': 'Contract plan',
  'You are about to purchase your STANDARD plan': 'You are about to purchase your STANDARD plan.',
  'You are about to purchase your PRO plan': 'You are about to purchase your PRO plan',
  'Fill in the details below to complete the purchase':
    'Fill in the details below to complete the purchase.',
  'Users quantity': 'Users quantity',
  'Select the number of users that will use uniboxi in your business. You can add or remove users at any time you want':
    'Select the number of users that will use uniboxi in your business. You can add or remove users at any time you want',
  'Order summary': 'Order summary',
  'Standard plan x': 'Standard plan x {{quantity}} ',
  'Pro plan x': 'Pro plan x {{quantity}} ',
  user: 'user',
  users: 'users',
  'Monthly fee': 'Monthly fee',
  Taxes: 'Taxes',
  'Order total': 'Order total',
  'Payment details': 'Payment details',
  'Available means of payment': 'Available means of payment',
  'Card number': 'Card number',
  'Name on card': 'Name on card',
  CCV: 'CCV',
  'Complete purchase': 'Complete purchase',
  'Thanks for your purchase': 'Thanks for your purchase',
  'Thank you for hiring Uniboxi. You can now use and enjoy all its functions for your business':
    'Thank you for hiring Uniboxi. You can now use and enjoy all its functions for your business.',
  'Add funds to be able to send WhatsApp messages through pre-approved templates':
    'Add funds to be able to send WhatsApp messages through pre-approved templates.',
  'Current balance': 'Current balance',
  'messages available': 'messages available',
  'Other amount': 'Other amount',
  Amount: 'Amount',
  'Minimun amount': 'Minimun amount $5',
  messages: 'messages',
  'Twilio cost help':
    'Twilio, the company that provides access to the WhatsApp platform, charges US $ 0.0083 for each pre-approved template used. Uniboxi works as an intermediary and does not charge an additional amount to this value established by Twilio. Uniboxi only retains 2.9% + 30 cents that are used to pay the charge for using the credit card.',
  'Proration adjustmen': 'Proration adjustmen',
  'It will be charged only in proportion to the days remaining for the next summary':
    'It will be charged only in proportion to the days remaining for the next summary.',
  lastUserDefaultRule:
    'It is not possible to deactivate the only user of the default assignment rule. Please <2>add another user to the rule</2> and try again.',
  lastUserRule:
   'It is not possible to deactivate the only user of the assignment rule {{ruleName}}. Please <2>add another user to the rule or remove it</2> and try again.',
  'Create default templates': 'Create default templates',
  roleError: 'There must be at least one admin user',
  Timezone: 'Timezone',
};
