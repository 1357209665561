export default {
  'Create template': 'Create template',
  'Created by': 'Created by',
  'Creation date': 'Creation date',
  Channel: 'Channel',
  Channels: 'Channels',
  'Choose the channels you wish to apply this template':
    'Choose the channels you wish to apply this template.',
  'Shared with everyone': 'Shared with everyone',
  'Assign channels': 'Assign channels',
  'Template visible only to myself.': 'Template visible only to myself.',
  'Template visible to all users of the account.': 'Template visible to all users of the account.',
  'Template content': 'Template content',
  'No templates': 'There is no templates yet',
  'No pre-approved templates': 'There is no pre-approved templates yet',
  'Create a template': 'Create a template',
  Attachment: 'Attachment',
  'Edit template': 'Edit template',
  'Edit template name': 'Edit name',
  'Template name': 'Template name',
  'Delete template': 'Delete template',
  'Missing fields on user': 'Missing fields on user',
  'Missing fields on template': 'Missing fields on template',
  'Assigned user name': 'Assigned user name',
  'Assigned user email': 'Assigned user email',
  'Assigned user phone': 'Assigned user phone',
  missing_fields_alert:
    'the user has {{missingFields}} unassigned fields used on this template. Assign them manually',
  delete_template_error: 'Template delete has failed',
  delete_template_help1:
    'Cannot delete this template. This template is used in the following sequence(s):',
  delete_template_help2:
    'To delete this template you will first need to remove it from that sequence(s).',
  'No templates help': 'Answer faster creating message templates to use with all your channels.',
  'First Name': 'First Name',
  'Last Name': 'Last Name',
  Standard: 'Standard',
  'No plan': 'No plan',
  'Pre-approved': 'Pre-approved',
  'No pre-approved templates help':
    'Pre-approved templates can be used to send WhatsApp messages once the 24-hour limit has been finalized since the last message from the client. According to Whatsapp policies.',
  'Request pre-approved template': 'Request pre-approved template',
  'Pre-approved template requested': 'Pre-approved template requested',
  'You want to request a pre-approved template?': 'You want to request a pre-approved template?',
  'Our support service will contact you immediately to complete the operation':
    'Our support service will contact you immediately to complete the operation.',
  'Your request has been sent successfully': 'Your request has been sent successfully.',
  'Full name': 'Full name',
  'Insufficient funds for pre-approved messages': 'Insufficient funds for pre-approved messages.',
  'Click to': 'Click to',
  'No pre-approved templates included':
    "Your current plan doesn't include pre-approved templates. The account admin can activate it ",
  'upgrading the plan': 'upgrading the plan',
  'Pre-approved templates help':
    'Pre-approved templates depend on Whatsapp approval. This process can take up to 24 hours approximately.',
  'Support mail': 'admin@uniboxi.com',
  'Update template': 'Update template',
  'Update template standard': 'Update standard template',
  'Update template pre-approved': 'Update pre-approved template',
  Spanish: 'Spanish',
  English: 'English',
  Language: 'Language',
  Category: 'Category',
  'Create template pre-approved': 'Create pre-approved template',
  'Create template standard': 'Create standard template',
  'To approve your template, WhatsApp requests that you provide an example of the following variables used.':
    'To approve your template, WhatsApp requests that you provide an example of the following variables used.',
  'Eg.: If you are using the Name field, write Peter or Alice as an example.':
    'Eg.: If you are using the Name field, write Peter or Alice as an example.',
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  number: 'Phone number',
  assignedUserName: 'assigned user name',
  assignedUserEmail: 'assigned user email',
  assignedUserPhone: 'assigned user phone',
  'The message template name can only contain lowercase alphanumeric characters. Use':
    'The message template name can only contain lowercase alphanumeric characters. Use',
  'The message template content field is limited to 1024 characters':
    'The message template content field is limited to 1024 characters',
  'invalid variables': 'These variables are invalid',
  'The message template name field is limited to 512 characters.':
    'The message template name field is limited to 512 characters',
  Approved: 'Approved',
  Pending: 'Pending',
  Rejected: 'Rejected',
  Flagged: 'Flagged',
  Disabled: 'Disabled',
  'Your template has been submitted and is awaiting review.': 'Your template has been submitted and is awaiting review.',
  'Your template has been rejected for use on your board.': 'Your template has been rejected for use on your board.',
  'Your template has been deactivated after spending 7 days in marked status.': 'Your template has been deactivated after spending 7 days in marked status.',
  'Your template has been successfully approved.': 'Your template has been successfully approved.',
  'Your template has been flagged, please contact support.': 'Your template has been flagged, please contact support.',
  'Message template is being deleted, please use other name': 'Message template is being deleted, please use other name',
  account_update: 'Account update',
  alert_update: 'Alert update',
  appointment_update: 'Appointment update',
  auto_reply: 'Auto reply',
  issue_resolution: 'Issue resolution',
  payment_update: 'Payment update',
  personal_finance_update: 'Personal finance update',
  reservation_update: 'Reservation update',
  shipping_update: 'Shipping update',
  ticket_update: 'Ticket update',
  transportation_update: 'Transportation update',
  'Name in use': 'Template with that name already exists',
  otp: 'One-Time password',
  utility: 'Utility',
  marketing: 'Marketing',
  'Message template components param is missing expected field(s)': 'Message template components param is missing expected field(s)',
  'Template with that body already exists.': 'Template with that content already exists.',
  'Whatsapp paid templates description': 'Pre-approved templates are only used in WhatsApp channels',
};
