export default {
  'Create sequence': 'Create sequence',
  'Delete sequence': 'Delete sequence',
  'Edit sequence': 'Edit sequence',
  'No sequences': 'No sequences created yet',
  'No sequences help':
    'The sequences will allow you to programmatically send a message chain to the desired contacts.',
  'Name of the sequence': 'Name of the sequence',
  Steps: 'Steps',
  Step: 'Step',
  'Created by': 'Created by',
  'Continue sequence if there is no response within':
    'Continue sequence if there is no response within',
  'Add template': 'Add template',
  'Add step': 'Add step',
  'Sequence modal help':
    'Schedule the time and day to start submitting this sequence, or start submitting the Step 1 template right away.',
  'This step will be sent if there is no answer in':
    'This step will be sent if there is no answer in',
  days: 'days',
  'Please select the template you want to use': 'Please select the template you want to use',
  'Add Element': 'Add Element',
  'Please select a template': 'Please select a template',
  'Ongoing sequence warning': 'There is a sequence in process, if you send a message it will stop.',
  'There are no templates, please create one': 'There are no templates, please create one',
  'Create a sequence': 'Create a sequence',
  'Go to sequences': 'Go to sequences',
  'Last message of sequence': 'Last message of sequence',
  'Interrupted sequence': 'Interrupted sequence',
  After: 'After',
  'without answer': 'without answer',
  sequence_start: 'Sequence {{name}} started',
  'Next step': 'Next step',
  Shared: 'Shared',
};
